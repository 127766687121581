import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import type { PropsWithChildren } from 'react';
import * as React from 'react';

import { OverlayElevation } from '@coral/layout/Overlay/types';

interface TooltipContentProps {
  side?: TooltipPrimitive.TooltipContentProps['side'];
  sideOffset?: TooltipPrimitive.TooltipContentProps['sideOffset'];
  align?: TooltipPrimitive.TooltipContentProps['align'];
  alignOffset?: TooltipPrimitive.TooltipContentProps['alignOffset'];
}

export type TooltipProps = PropsWithChildren<{
  title: React.ReactNode;
  className?: string;
  open?: boolean;
  tooltipContentProps?: TooltipContentProps;
  asChild?: TooltipPrimitive.TooltipTriggerProps['asChild'];
  delayDuration?: number;
  usePortal?: boolean;
}>;

const DEFAULT_DELAY_DURATION = 0;

const TooltipPortalWrapper = ({
  usePortal,
  children,
}: PropsWithChildren<{ usePortal?: boolean }>) => {
  if (usePortal) {
    return <TooltipPrimitive.Portal>{children}</TooltipPrimitive.Portal>;
  }

  return <>{children}</>;
};

// TODO: https://snorkelai.atlassian.net/browse/ENG-15666 is a cleanup ticket for this component
const Tooltip = React.forwardRef<HTMLButtonElement, TooltipProps>(
  (
    {
      children,
      title,
      className,
      open,
      tooltipContentProps,
      asChild = true,
      delayDuration = DEFAULT_DELAY_DURATION,
      usePortal = false,
    },
    ref,
  ) => {
    if (!title) return <>{children}</>;

    return (
      <TooltipPrimitive.Provider>
        <TooltipPrimitive.Root open={open} delayDuration={delayDuration}>
          <TooltipPrimitive.Trigger asChild={asChild} ref={ref}>
            {children}
          </TooltipPrimitive.Trigger>
          <TooltipPortalWrapper usePortal={usePortal}>
            <TooltipPrimitive.Content
              sideOffset={4}
              className={`rounded bg-black p-3 shadow-sm ${
                OverlayElevation.TOOLTIP
              } ${className || ''}`}
              avoidCollisions
              {...tooltipContentProps}
              data-cy="tooltip-content"
            >
              <TooltipPrimitive.Arrow
                width={8}
                height={5}
                className="fill-black"
              />
              <span className="text-white">{title}</span>
            </TooltipPrimitive.Content>
          </TooltipPortalWrapper>
        </TooltipPrimitive.Root>
      </TooltipPrimitive.Provider>
    );
  },
);

Tooltip.displayName = 'Tooltip';

export default Tooltip;
