import { isEmpty } from 'lodash';

import { Icons } from '@coral/components/Icon';
import type { NodeDetails } from '@core/types';
import type {
  NavigationId,
  NavigationItem,
} from '@global/NavigationContainer/types';
import type { AppDetailsState } from '@global/state/reducers/appDetails/types';

import { APP_SETUP, PIPELINE } from '../../constants';

export const isNavItemVisible = (
  itemId: NavigationId,
  isSetupComplete,
  isOnboardingComplete,
  isMultiNode = false,
) => {
  switch (itemId) {
    case 'application-setup':
      return !isMultiNode && (!isSetupComplete || isOnboardingComplete);
    case 'pipeline':
      return true;
    case 'jobs':
      return isSetupComplete;
    default:
      return false;
  }
};

// Returns app-specific items for sidebar nav, separate from node-specific
// items due to difference in params required. Items sorted in UI via CSS order
// property
const calcAppNavItems = ({
  application,
  isOnboardingComplete,
  path,
}: {
  application: AppDetailsState;
  isOnboardingComplete: boolean;
  path: string;
}): NavigationItem[] => {
  const { isSetupComplete } = application;

  if (!application || isEmpty(application)) {
    return [];
  }

  const isAppPage = path.match(/^\/applications\/.+/);

  if (!isAppPage) {
    return [];
  }

  const { dag: nodes = {} } = application;
  const modelNodes = Object.values(nodes as Record<string, NodeDetails>).filter(
    node => {
      return node.expected_op_type === 'Model';
    },
  );
  const isMultiNodeApp = modelNodes.length > 1;

  const navItems: NavigationItem[] = [
    {
      id: 'application-setup',
      href: `/applications/new/app/${application.id}`,
      icon: Icons.OVERVIEW,
      title: APP_SETUP,
      isVisible: isNavItemVisible(
        'application-setup',
        isSetupComplete,
        isOnboardingComplete,
        isMultiNodeApp,
      ),
      isDisabled: !application,
      isSelected: path.includes('applications/new/app'),
      order: 1,
    },
    {
      id: 'pipeline',
      href: `/applications/${application.id}/pipeline`,
      icon: Icons.DAG,
      title: PIPELINE,
      isVisible: isNavItemVisible(
        'pipeline',
        isSetupComplete,
        isOnboardingComplete,
      ),
      isDisabled: !application,
      isSelected: path.includes('pipeline'),
      order: isMultiNodeApp ? 2 : 6,
    },
  ];

  return navItems;
};

export default calcAppNavItems;
