import type { SnorkelSemanticPalette } from '@snorkel-themes/themes/types';

import palette from './palette';

const global: SnorkelSemanticPalette['global'] = {
  disabled: palette.gray[500],
};

const text: SnorkelSemanticPalette['text'] = {
  primary: palette.gray[900],
  secondary: palette.gray[600],
  placeholder: palette.gray[500],
  inverse: palette.gray[20],
  link: palette.blue[700],
  good: palette.green[600],
  bad: palette.red[700],
};

const primaryButtonBackground: SnorkelSemanticPalette['button']['background']['primary'] =
  {
    enabled: palette.blue[700],
    hover: palette.blue[800],
    pressed: palette.blue[600],
    disabled: global.disabled,
  };

const semanticColors: SnorkelSemanticPalette = {
  button: {
    background: {
      dropdown: {
        hover: palette.squidInkAlpha[4],
        selected: palette.squidInkAlpha[8],
      },
      primary: primaryButtonBackground,
      secondary: {
        hover: palette.gray[100],
        pressed: palette.gray[100],
      },
      tertiary: {
        hover: palette.gray[100],
        pressed: palette.gray[100],
      },
      toggleGroup: {
        hover: palette.blackAlpha[5],
      },
      toggle: {
        hover: palette.blackAlpha[5],
        pressed: palette.blue[200],
        selected: palette.blue[200],
        selectedHover: palette.blue[300],
      },
    },
    border: {
      primary: primaryButtonBackground,
      secondary: {
        enabled: palette.gray[900],
        hover: palette.gray[900],
        pressed: palette.gray[1000],
        disabled: palette.gray[500],
      },
      tertiary: {
        enabled: palette.surfAlpha[0],
        hover: palette.surfAlpha[0],
        pressed: palette.surfAlpha[0],
        disabled: palette.surfAlpha[0],
      },
      toggle: {
        enabled: palette.surfAlpha[0],
        hover: palette.surfAlpha[0],
        pressed: palette.surfAlpha[0],
        disabled: palette.surfAlpha[0],
        selected: palette.blue[200],
        selectedHover: palette.blue[300],
      },
    },
    icon: {
      primary: {
        enabled: palette.white,
        hover: palette.white,
        pressed: palette.white,
        disabled: palette.white,
      },
      secondary: {
        enabled: text.primary,
        hover: text.primary,
        pressed: text.primary,
        disabled: global.disabled,
      },
      tertiary: {
        enabled: palette.gray[900],
        hover: palette.gray[900],
        pressed: palette.gray[1000],
        disabled: global.disabled,
      },
      toggle: {
        enabled: palette.gray[600],
        hover: palette.gray[700],
        pressed: palette.blue[700],
        disabled: global.disabled,
        selected: palette.blue[700],
      },
      text: {
        primary: {
          enabled: palette.white,
          hover: palette.white,
          pressed: palette.white,
          disabled: palette.white,
        },
        secondary: {
          enabled: text.primary,
          hover: text.primary,
          pressed: text.primary,
          disabled: global.disabled,
        },
        tertiary: {
          enabled: palette.gray[900],
          hover: palette.gray[900],
          pressed: palette.gray[1000],
          disabled: global.disabled,
        },
        toggle: {
          enabled: palette.gray[600],
          hover: palette.gray[800],
          pressed: palette.blue[700],
          disabled: global.disabled,
          selected: palette.blue[700],
        },
      },
    },
  },
  global,
  icon: {
    primary: palette.gray[900],
    secondary: palette.gray[600],
  },
  surface: {
    application: palette.white,
    panel: '#f2f4f8',
    panelHover: '#dde1e6',
    panelBorder: '#f2f4f8',
    dropdown: palette.white,
    tooltip: palette.gray[1000],
    divider: '#dde1e6',
    inputField: {
      border: '#dde1e6',
    },
    fieldBackground: palette.white,
    good: palette.green[600],
    bad: palette.red[700],
    infoLine: palette.gray[500],
  },
  text,
  component: {
    dataTable: {
      hover: palette.gray[100],
    },
  },
};

export default semanticColors;
