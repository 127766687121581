import SnorkelLink from '@components/SnorkelLink';
import Icon from '@coral/components/Icon';
import Spinner from '@coral/components/Spinner';
import Tooltip from '@coral/components/tooltips/TooltipV2';

import type { NavigationItem } from '../types';

type NavItemProps = NavigationItem;

const NavItem = ({
  href,
  icon,
  title,
  isVisible,
  isDisabled = false,
  isSelected = false,
  isLoading = false,
  subItems = [],
  shouldPrefetch = false,
  order,
  disabledTooltip,
  rightIcon,
  shouldOpenNewTab = false,
}: NavItemProps) => {
  const listItemClasses = isVisible ? '' : 'hidden';
  const linkClasses = `flex select-none items-center gap-4 rounded-md px-3 py-1.5 ${
    isDisabled
      ? 'cursor-not-allowed text-gray-500 focus:outline-none'
      : 'hover:bg-gray-100'
  } ${isSelected ? 'bg-gray-100' : ''}`;

  const shouldShowSubItems = subItems.some(item => item.isVisible);

  // Can't use template literals for tailwind classnames, e.g., `order-${num}`,
  // so we need to use inline style for order
  const listItemStyle = {
    order: order || 'none',
  };

  return (
    <li style={listItemStyle} className={listItemClasses}>
      <SnorkelLink
        href={href}
        passHref
        target={shouldOpenNewTab ? '_blank' : '_self'}
        prefetch={shouldPrefetch}
        className={linkClasses}
        onClick={e => {
          // <a> tag doesn't have a disabled attribute
          // so we need to use preventDefault as a workaround
          // we could also probably get away with pointer-events-none
          // but that does not allow adding custom cursors so went with this
          // approach to have a better user experience for disabled state
          if (isDisabled) e.preventDefault();
        }}
        // we don't want the link to be reachable via keyboard when its disabled
        tabIndex={isDisabled ? -1 : 0}
        aria-disabled={isDisabled}
        data-cy={`nav-sidebar-link-${title.toLowerCase()}`}
      >
        <Tooltip title={isDisabled ? disabledTooltip : undefined}>
          <div className="flex flex-1 items-center gap-3">
            <Icon name={icon} disabled={isDisabled} />
            <div className="flex-1">{title}</div>
            {isLoading ? <Spinner /> : null}
            {rightIcon ? <Icon name={rightIcon} disabled={isDisabled} /> : null}
          </div>
        </Tooltip>
      </SnorkelLink>
      {shouldShowSubItems ? (
        <ul className="ml-4 mt-2 gap-y-2">
          {subItems.map(item => (
            <NavItem key={`app-nav-subitem-${item.id}`} {...item} />
          ))}
        </ul>
      ) : null}
    </li>
  );
};

type CurrentAppNavProps = {
  navItems: NavigationItem[];
};

const CurrentAppNav = ({ navItems }: CurrentAppNavProps) => {
  return (
    <>
      {navItems.map(item => (
        <NavItem key={`app-nav-item-${item.id}`} {...item} />
      ))}
    </>
  );
};

export default CurrentAppNav;
