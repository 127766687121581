import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import type { UpdateUserSettingsRequest } from '@api/tdm';
import * as selectors from '@global/state/selectors';
import useSnorkelRouter from '@hooks/useSnorkelRouter';
import { validateRequestParam } from '@utils/validateRequestParams';

export type UserSettingsRequestParams = Omit<
  UpdateUserSettingsRequest,
  'settings'
>;

export type RequestParams = UserSettingsRequestParams & {
  batch_uid?: number;
  dataset_uid?: number;
  evaluation_report_uid?: number;
  workflow_uid?: number;
};

// TODO(ENG-24078): Make these camelCase now that the BE accepts camelCase objects
export const defaultRequestParams: RequestParams = {
  organization: undefined,
  application_uid: undefined,
  node_uid: undefined,
  user_uid: undefined,
  batch_uid: undefined,
  dataset_uid: undefined,
  dataset_batch_uid: undefined,
  evaluation_report_uid: undefined,
  workflow_uid: undefined,
};

const useGetRequestParams = () => {
  const userUid = useSelector(selectors.auth.selectUserUid);
  // Use appId and nodeId from query params to minimize rerenders from root context updates.
  const {
    query: {
      appId = undefined,
      nodeId = undefined,
      batchUid = undefined,
      datasetId = undefined,
      batchId = undefined,
      evaluationReportId = undefined,
      workflowId = undefined,
    } = {},
  } = useSnorkelRouter() || {};

  const requestParams = useMemo(
    () => ({
      ...defaultRequestParams,
      application_uid: validateRequestParam(appId),
      node_uid: validateRequestParam(nodeId),
      user_uid: validateRequestParam(userUid),
      batch_uid: validateRequestParam(batchUid),
      dataset_uid: validateRequestParam(datasetId),
      dataset_batch_uid: validateRequestParam(batchId),
      evaluation_report_uid: validateRequestParam(evaluationReportId),
      workflow_uid: validateRequestParam(workflowId),
    }),
    [
      appId,
      batchUid,
      nodeId,
      userUid,
      datasetId,
      batchId,
      evaluationReportId,
      workflowId,
    ],
  );

  return requestParams;
};

export default useGetRequestParams;
