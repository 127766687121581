import { useSelector } from 'react-redux';

import Button from '@coral/components/Button';
import Icon, { Icons } from '@coral/components/Icon';
import Tooltip from '@coral/components/tooltips/TooltipV2';
import * as selectors from '@global/state/selectors';
import useCachedRequest from '@hooks/useCachedRequest';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import { applicationsApi } from '@utils/api/serverRequests';

const NotebookLeftToolbar = () => {
  const { handleCopy } = useCopyToClipboard();
  const datasetName = useSelector(selectors.task.selectDatasetName);
  const nodeId = useSelector(selectors.task.selectNodeId);
  const applicationUid = useSelector(selectors.task.selectApplicationUid)!;

  const { data: { name: applicationName = '' } = {} } = useCachedRequest(
    applicationsApi.getApplicationApplicationsApplicationUidGet,
    { applicationUid },
    { suspendFetch: !applicationUid },
  );

  return (
    <div className="ml-4 flex items-center text-sm text-gray-700">
      {datasetName && (
        <div className="flex items-center">
          {datasetName}
          <Tooltip title="copy dataset name">
            <Button
              border={false}
              className="mr-2"
              id="copy-dataset-name"
              onClick={() => handleCopy(datasetName)}
              data-cy="copy-dataset-name"
            >
              <Icon name={Icons.COPY__FILE} />
            </Button>
          </Tooltip>
        </div>
      )}
      {datasetName && applicationName && '/'}
      {applicationName && (
        <div className="ml-2 flex items-center">
          {applicationName}
          <Tooltip title="copy application name">
            <Button
              border={false}
              className="mr-2"
              id="copy-application-name"
              data-cy="copy-application-name"
              onClick={() => handleCopy(applicationName)}
            >
              <Icon name={Icons.COPY__FILE} />
            </Button>
          </Tooltip>
        </div>
      )}
      {applicationName && nodeId && '/'}
      {nodeId && (
        <div className="ml-2 flex items-center">
          Node {nodeId}
          <Tooltip title="copy node ID">
            <Button
              border={false}
              id="copy-node-name"
              data-cy="copy-node-name"
              onClick={() => handleCopy(nodeId.toString())}
            >
              <Icon name={Icons.COPY__FILE} />
            </Button>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default NotebookLeftToolbar;
