import Dropdown from '@coral/components/Dropdown';
import DropdownOption from '@coral/components/Dropdown/DropdownOption';
import type { DropdownOptionType } from '@coral/components/Dropdown/types';
import { InputSizes } from '@coral/components/InputContainer/types';
import combineClasses from '@coral/utils/combineClasses';
import type { NodeDetails } from '@core/types';
import type { AppDetailsState } from '@global/state/reducers/appDetails/types';

const NODE_SELECTOR_PLACEHOLDER = 'Select model node';

interface NodeSelectorProps {
  application: AppDetailsState;
  className?: string;
  value?: number;
  onNodeChange: (nodeId: number) => void;
}

const getNodeLabel = (nodeId: number | string) => `Model node #${nodeId}`;

const NodeSelector = ({
  application,
  className,
  value,
  onNodeChange,
}: NodeSelectorProps) => {
  const { dag: nodes = {} } = application || {};

  // obj to map node display string to node ID, so we don't have to parse it all the time
  const nodeLabelMap = Object.entries(nodes as Record<string, NodeDetails>)
    .filter(([_nodeId, node]) => node.expected_op_type === 'Model')
    .reduce(
      (acc, [nodeId]) => {
        const nodeString = getNodeLabel(nodeId);
        acc[nodeString] = Number(nodeId);

        return acc;
      },
      {} as Record<string, number>,
    );

  const handleNodeChange = (option: DropdownOptionType<number>) => {
    const { value: nextNodeId } = option;

    onNodeChange(nextNodeId);
  };

  if (Object.keys(nodeLabelMap).length < 2) {
    return null;
  }

  return (
    <li className={combineClasses('order-3', className)}>
      <Dropdown<number>
        fullWidth
        value={value}
        size={InputSizes.small}
        onSelect={handleNodeChange}
        placeholder={NODE_SELECTOR_PLACEHOLDER}
        data-cy="sidebarnav-node-selector"
      >
        {Object.entries(nodeLabelMap).map(([label, nodeId]) => (
          <DropdownOption key={`node-selector-option-${nodeId}`} value={nodeId}>
            {label}
          </DropdownOption>
        ))}
      </Dropdown>
    </li>
  );
};

export default NodeSelector;
