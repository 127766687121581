import type { Workflow } from '@api/tdm/api';
import { Icons } from '@coral/components/Icon';
import type { NavigationItem } from '@global/NavigationContainer/types';

export const calcWorkflowNavItems = ({
  promptWorkflow,
  path,
}: {
  promptWorkflow: Workflow;
  path: string;
}): NavigationItem[] => {
  const navItems: NavigationItem[] = [
    {
      id: 'prompt-develop',
      href: `/prompts/${promptWorkflow.workflow_uid}`,
      icon: Icons.DEVELOPMENT,
      title: 'Develop prompt',
      isVisible: true,
      isDisabled: false,
      isSelected: path.includes(`/prompts/${promptWorkflow.workflow_uid}`),
      order: 1,
    },
    {
      id: 'prompt-annotate',
      href: `/datasets/${promptWorkflow.output_dataset_uid}?selectedTab=batches`,
      icon: Icons.DOCUMENTATION,
      title: 'Annotate',
      isVisible: true,
      isDisabled: !promptWorkflow?.output_dataset_uid,
      isSelected: false,
      order: 2,
      disabledTooltip:
        'Run your first prompt to start annotating LLM responses.',
      rightIcon: Icons.LAUNCH,
      shouldOpenNewTab: true,
    },
  ];

  return navItems;
};

export default null;
