import { init as initFullStory } from '@fullstory/browser';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import type { Response } from 'express';
import type { NextPage } from 'next';
import type { AppContext } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import { withSecureHeaders } from 'next-secure-headers';
import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import type { GetCurrentUserResponse } from '@api/tdm';
import FeatureFlagInitializer from '@components/FeatureFlagInitializer';
import JobProgressNotificationsList from '@components/JobProgressNotificationsList';
import { NotificationsContainer } from '@components/Notifications';
import SkipToMainContent from '@components/SkipToMainContent';
import UserAnalyticsInitializer from '@components/UserAnalyticsInitializer';
import { CoralContextProvider } from '@coral/components/CoralContext';
import { PrivateRoutes } from '@core/constants';
import { TITLE } from '@core/strings';
import AuthInitializer from '@global/AuthInitializer';
import CacheProvider from '@global/CacheProvider';
import NavigationWrapper from '@global/NavigationWrapper';
import NotificationWebSocketHandler from '@global/NotificationWebSocketsWrapper';
import RootApp from '@global/RootApp';
import UsersInitializer from '@global/state/reducers/users/UsersInitializer';
import UserSettingsInitializer from '@global/state/reducers/userSettings/UserSettingsInitializer';
import WorkspaceInitializer from '@global/state/reducers/workspaces/WorkspaceInitializer';
import type { PreFetchedData } from '@global/state/store';
import { makeStore } from '@global/state/store';
import generateDefaultHeaders from '@hooks/useRequest/utils/generateDefaultHeaders';
import { calcBasePath } from '@utils/api/calcBasePath';
import {
  tdmServerRequest,
  userSettingsApi,
  workspacesApi,
} from '@utils/api/serverRequests';
import auth from '@utils/auth';
import getIsPublicRoute from '@utils/auth/utils/getIsPublicRoute';
import { calcApiUrl } from '@utils/calcApiUrl';
import { Flags } from '@utils/getFlag';
import getAllFlags from '@utils/getFlag/utils/getAllFlags';
import getIsServerRendered from '@utils/getIsServerRendered';
import initSentry from '@utils/initSentry';
import isPageGatedByRunningJob from '@utils/isPageGatedByRunningJob';
import { normalizeUrl } from '@utils/normalizeUrl';
import parseJWTFromAccessToken from '@utils/parseJWTFromAccessToken';
import patchConsoleError from '@utils/patchConsoleError';
import setWindowLocationHref from '@utils/setWindowLocationHref';

import Error from './_error';

import theme from '../theme';

import '@coral/styles/snorkel.css';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import '../css/tailwind.css';

patchConsoleError();

type AppProps = {
  accessToken: string;
  accessTokenExpiry: number;
  existingUser?: GetCurrentUserResponse;
  statusCode?: number;
};

type AppState = {
  isPublicRoute: boolean;
};

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

// Jupyter is only available on production deployments.
// To prevent a reload loop of Jupyter and the accessToken we serve the 404 page instead.
const jupyterPaths = [
  '/jupyterhub/', // e.g. /jupyterhub/user/<username>/tree
  '/notebook/', // e.g. /notebook/notebooks/user-3/DefaultNotebook.ipynb
];

/**
 * Accessibility tool - outputs to devtools console on dev only and client-side only.
 * @see https://github.com/dequelabs/react-axe
 */
if (process.env.NODE_ENV !== 'production' && !getIsServerRendered()) {
  if (window.location.search.includes('axe=true')) {
    // eslint-disable-next-line global-require
    const ReactDOM = require('react-dom');

    // eslint-disable-next-line
    const axe = require('react-axe');

    axe(React, ReactDOM, 1000);
  }
}

const basePath = calcBasePath();

const FULLSTORY_ORG_ID = '17N11F';
class MyApp extends App<AppPropsWithLayout, PreFetchedData, AppState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isPublicRoute: getIsServerRendered()
        ? props.isPublicRoute
        : getIsPublicRoute(window.location.pathname),
    };
  }

  static async getInitialProps(appContext: AppContext) {
    const location =
      (getIsServerRendered() ? appContext.router : appContext.ctx) || {};
    const currentPath = (location.asPath || '').split('?')[0];

    const isPublicRoute = getIsPublicRoute(currentPath);

    const { accessToken, user: existingUser } = await auth(
      appContext.ctx,
      { currentPath, isPublicRoute },
      location.query || {},
    );

    const getComponentInitialProps = async () => {
      if (!appContext.Component.getInitialProps) {
        return App.getInitialProps(appContext);
      }

      // App.getInitialProps basically calls below, and wraps the result around { pageProps }
      // see https://nextjs.org/docs/advanced-features/custom-app
      const pageProps = await appContext.Component.getInitialProps({
        ...appContext.ctx,
        accessToken,
        existingUser,
      } as any);

      return { pageProps };
    };

    const appProps = await getComponentInitialProps();

    const hostname =
      appContext.ctx.req?.headers['x-forwarded-host']?.toString() ??
      appContext.ctx.req?.headers.host ??
      '';
    appProps.pageProps.flags = getAllFlags(hostname, appContext.ctx.query);
    appProps.pageProps.hostname = hostname;

    try {
      // Test that tdm url is valid [ch2103]
      if (getIsServerRendered()) {
        await tdmServerRequest('');
      } else {
        const apiUrl = calcApiUrl();

        await fetch([apiUrl, 'license', 'validate'].join('/'), {
          headers: generateDefaultHeaders(),
        });
      }
    } catch (err: any) {
      return { ...appProps, isPublicRoute, statusCode: 500 };
    }

    if (
      !isPublicRoute &&
      (await isPageGatedByRunningJob(currentPath, accessToken))
    ) {
      const dataSourceUrl = `${currentPath
        .split('/')
        .slice(0, -1)
        .join('/')}/datasources`;

      if (getIsServerRendered() && appContext.ctx.res) {
        appContext.ctx.res.writeHead(302, {
          Location: normalizeUrl(dataSourceUrl),
        });

        appContext.ctx.res.end();

        return { ...appProps, isPublicRoute };
      }

      setWindowLocationHref(normalizeUrl(dataSourceUrl));
    }

    if (accessToken) {
      const { has_assigned_workspaces } = parseJWTFromAccessToken(accessToken);

      if (
        !has_assigned_workspaces &&
        appContext.router.pathname !== PrivateRoutes.NO_WORKSPACES
      ) {
        (appContext.ctx.res as Response)?.redirect(PrivateRoutes.NO_WORKSPACES);
      }

      const [userSettings, workspaces] = await Promise.all([
        userSettingsApi.getUserSettingsUserSettingsGet(
          {
            userUid: existingUser?.user_uid,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        ),
        workspacesApi.listWorkspacesWorkspacesGet(
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        ),
      ]);

      appProps.pageProps.workspaces = workspaces.data;
      appProps.pageProps.userSettings = userSettings.data;
      appProps.pageProps.existingUser = existingUser;
    }

    return {
      ...appProps,
      accessToken,
      existingUser,
      isPublicRoute,
    };
  }

  componentDidMount = () => {
    const jssStyles = document.querySelector('#jss-server-side');

    if (this.props.pageProps.flags?.[Flags.FULL_STORY]) {
      initFullStory({
        orgId: FULLSTORY_ORG_ID,
        devMode: process.env.NODE_ENV !== 'production',
      });
    }

    initSentry();

    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  };

  static getDerivedStateFromProps(): any | null {
    if (getIsServerRendered()) {
      return {};
    }

    return {
      isPublicRoute: getIsPublicRoute(window.location.pathname),
    };
  }

  render = () => {
    const {
      props: {
        Component,
        pageProps,
        accessToken = '',
        existingUser,
        statusCode,
        router,
      },
    } = this;

    const { isPublicRoute } = this.state as AppState;

    if (
      jupyterPaths.some(path => router.asPath.includes(path)) &&
      router.pathname === '/_error'
    ) {
      return <Error statusCode={404} />;
    }

    if (statusCode && (statusCode >= 500 || statusCode === 404)) {
      // We only want to show the error for 500s, 401s are valid health checks
      return <Error statusCode={statusCode} />;
    }

    const defaultGetLayout = (page: ReactElement) => (
      <NavigationWrapper isPublicRoute={isPublicRoute}>
        {page}
      </NavigationWrapper>
    );
    const getLayout = Component.getLayout ?? defaultGetLayout;
    const renderPageContent = () =>
      getLayout(<Component {...(pageProps as any)} />);

    const renderHead = () => (
      <Head>
        <title>{TITLE}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <link rel="icon" href={normalizeUrl('/static/favicon.ico')} />
        <meta name="theme-color" content={theme.palette.primary.main} />
      </Head>
    );

    const renderBody = () => (
      <ThemeProvider theme={theme}>
        <ReduxProvider store={makeStore(pageProps)}>
          <CssBaseline />
          <CoralContextProvider basePath={basePath}>
            <AuthInitializer
              accessToken={accessToken}
              existingUser={existingUser}
              basePath={basePath}
              isPublicRoute={isPublicRoute}
            >
              <RootApp>
                <CacheProvider>
                  {!isPublicRoute ? (
                    <>
                      <UserSettingsInitializer />
                      <WorkspaceInitializer />
                      <UsersInitializer />
                      <NotificationWebSocketHandler>
                        <SkipToMainContent />
                        {renderPageContent()}
                      </NotificationWebSocketHandler>
                    </>
                  ) : (
                    renderPageContent()
                  )}
                  <JobProgressNotificationsList />
                  <UserAnalyticsInitializer />
                  <FeatureFlagInitializer />
                </CacheProvider>
              </RootApp>
            </AuthInitializer>
            <NotificationsContainer />
          </CoralContextProvider>
        </ReduxProvider>
      </ThemeProvider>
    );

    return (
      <>
        {renderHead()}
        {renderBody()}
      </>
    );
  };
}

const ProcessedApp =
  process.env.NODE_ENV === 'production'
    ? withSecureHeaders({
        contentSecurityPolicy: {
          directives: {
            defaultSrc: "'self'",
            styleSrc: ['*', 'data:', 'blob:', "'unsafe-inline'"],
            scriptSrc: [
              '*',
              'data:',
              'blob:',
              "'unsafe-inline'",
              "'unsafe-eval'",
            ],
            imgSrc: ['*', 'data:', 'blob:'],
            fontSrc: ['*', 'data:', 'blob:'],
            connectSrc: ['*', 'data:', 'blob:'],
            mediaSrc: ['*', 'data:', 'blob:', 'filesystem:'],
            frameSrc: ['*', 'data:', 'blob:'],
            frameAncestors: ['*', 'data:', 'blob:'],
          },
        },
      })(MyApp)
    : MyApp;

export default ProcessedApp;
