import tailwindConfig from 'tailwindcss/defaultTheme';
import type { ThemeConfig } from 'tailwindcss/types/config';

import type { SnorkelSizes } from './types';

export const letterSpacing = {
  ...tailwindConfig.letterSpacing,
  1: '0.01em', // to be deprecated
  2: '0.02em', // to be deprecated
  narrow1: '-0.03rem',
  narrow2: '-0.016rem',
  wide: '0.006rem',
  mono: '1%',
} satisfies ThemeConfig['letterSpacing'];

// tailwind keyframes are inlined because `tailwindcss-themer` doesn't allow
// whitespace in names, which `tailwind` uses for keyframes.
export const keyframes = {
  // define keyframes in snorkel.css. See https://github.com/RyanClementsHax/tailwindcss-themer/issues/155
} satisfies ThemeConfig['keyframes'];

export const animation = {
  ...tailwindConfig.animation,
  // for label button click
  keypress: 'keypress 0.2s ease-in-out',
  // for accordions open/close
  accordionDown: 'accordionDown 0.2s var(--easingFnSwing)',
  accordionUp: 'accordionUp 0.2s var(--easingFnSwing)',
  // for collapse open/close
  collapseDown: 'collapseDown 0.2s var(--easingFnSwing)',
  collapseUp: 'collapseUp 0.2s var(--easingFnSwing)',
  // For loading progress bars
  indeterminateLoading: 'indeterminateLoading 1.3s infinite ease-out',
  // for popover
  slideUpAndFade: 'slideUpAndFade 0.2s var(--easingFnOutQuint)',
  slideRightAndFade: 'slideRightAndFade 0.2s var(--easingFnOutQuint)',
  slideDownAndFade: 'slideDownAndFade 0.2s var(--easingFnOutQuint)',
  slideLeftAndFade: 'slideLeftAndFade 0.2s var(--easingFnOutQuint)',
  load: 'load 1s ease-in-out infinite',
  flashInOut: 'flashInOut 1s infinite ease-in-out',
} satisfies ThemeConfig['animation'];

/**
 *  ! Any change to a key in this z-index schema must be reflected in critical-fe-ratchet.json
 *  in order for the ratchet to behave as expected.
 *
 *  We use values from 1000+ for z-indexes to avoid conflicts with MUI components, which have specific z-indexes in the 1000+ range.
 *  We should revisit & simplify index ordering once MUI deprecation is complete
 * @see https://mui.com/material-ui/customization/z-index/
 */
export const zIndex = {
  ...tailwindConfig.zIndex,
  auto: 'auto',
  behind: '-1',
  base: '0',
  content: '1100',
  // This is the darker overlay which will cover most of the screen except what we want to treat as sidebar
  'sidebar-overlay': '1150',
  sidebar: '1175',
  overlay: '1200',
  // Maps to MUI modal z-index
  modal: '1300',
  popover: '1400',
  dropdown: '1450',
  // Maps to MUI tooltip z-index
  tooltip: '1500',
  notification: '1600',
  max: '9999',
} satisfies ThemeConfig['zIndex'];

export const opacity = {
  ...tailwindConfig.opacity,
  0: '0',
  25: '.25',
  50: '.5',
  75: '.75',
  10: '.1',
  20: '.2',
  30: '.3',
  40: '.4',
  60: '.6',
  70: '.7',
  80: '.8',
  90: '.9',
  100: '1',
} satisfies ThemeConfig['opacity'];

export const screens = {
  print: { raw: 'print' },
} satisfies ThemeConfig['screens'];

export const borderWidth = {
  ...tailwindConfig.borderWidth,
  '3/2': '1.5px',
} satisfies ThemeConfig['borderWidth'];

export const spacing = {
  ...tailwindConfig.spacing,
  124: '31rem',
  92: '23rem',
  72: '18rem',
} satisfies ThemeConfig['spacing'];

export const width = {
  ...tailwindConfig.width,
  form: '400px',
  'slim-modal': '336px',
  'slim-modal-content': '304px',
  'dialog-content': '600px',
  aside: '360px',
  4.5: '1.125rem',
  25: '6.25rem',
  28: '7rem',
  30: '7.5rem',
  36: '9rem',
  44: '11rem',
  50: '12.5rem',
  56: '14rem',
  '11/24': '45.833%',
  '13/24': '54.1%',
  60: '15rem',
  68: '17rem',
  72: '18rem',
  80: '20rem',
  84: '21rem',
  87.5: '21.875rem',
  88: '22rem',
  96: '24rem',
  100: '25rem',
  112: '28rem',
  128: '32rem',
  144: '36rem',
  160: '40rem',
  176: '44rem',
  '24/25': '96%',
  '1px': '1px',
  smallButton: '27px',
  max: 'max-content',
  buttonIcon: '1rem',
  clusterMap: '280px',
  lfAdvancedSettingsMenu: '292px',
} satisfies ThemeConfig['width'];

export const height = {
  ...tailwindConfig.height,
  toolbar: '52px',
  studioToolbar: '79px',
  3: '0.75rem',
  4.5: '1.125rem',
  7: '1.75rem',
  7.5: '1.875rem',
  11: '2.75rem',
  12.5: '3.125rem',
  18: '4.5rem',
  30: '7.5rem',
  48: '12rem',
  '24/25': '96%',
  '1px': '1px',
  '40px': '40px',
  smallButton: '27px',
  input: '2.5rem',
  buttonIcon: '1rem',
  barChart: '300px',
  clusterMap: '280px',
} satisfies ThemeConfig['height'];

export const minWidth = {
  ...tailwindConfig.minWidth,
  2: '0.5rem',
  3: '0.75rem',
  8: '2rem',
  14: '3.5rem',
  20: '5rem',
  24: '6rem',
  25: '6.25rem',
  27.5: '6.875rem',
  32: '8rem',
  40: '10rem',
  50: '12.5rem',
  52: '15rem',
  76: '19rem',
  87.5: '21.875rem',
  aside: '350px',
  '1/2': '50%',
  'custom-new-lf-button': '9.75rem',
  popup: '360px',
  fit: 'fit-content',
} satisfies ThemeConfig['minWidth'];

export const minHeight = {
  ...tailwindConfig.minHeight,
  input: '40px',
  8: '2rem',
  10: '2.5rem',
  12: '3rem',
  18: '4.5rem',
  toolbar: '52px',
  studioToolbar: '79px',
  32: '8rem',
  36: '9rem',
  40: '10rem',
  64: '16rem',
  80: '20rem',
  '75h': '75vh',
} satisfies ThemeConfig['minHeight'];

export const maxWidth = {
  ...tailwindConfig.maxWidth,
  'slim-modal': '336px',
  'prediction-item': '212px',
  50: '12.5rem',
  80: '18rem',
  DEFAULT: '1680px',
  p: '600px',
  utterance: '70%',
  conversation: '1000px',
  half: '50%',
} satisfies ThemeConfig['maxWidth'];

export const maxHeight = {
  ...tailwindConfig.maxHeight,
  28: '7rem',
  40: '10rem',
  48: '12rem',
  52: '13rem',
  56: '14rem',
  76: '19rem',
  80: '20rem',
  '500px': '500px',
  '35h': '35vh',
  '45h': '45vh',
  '75h': '75vh',
} satisfies ThemeConfig['maxHeight'];

export const inset = {
  ...tailwindConfig.inset,
  0: '0px',
  4: '4px',
  16: '16px',
  24: '24px',
  32: '32px',
  96: '96px', // 6rem
  112: '112px',
  128: '128px',
  144: '144px',
  160: '160px',
  176: '176px',
  192: '192px', // 12rem
  208: '208px',
} satisfies ThemeConfig['inset'];

export const boxShadow = {
  ...tailwindConfig.boxShadow,
  sm: '0px 6px 6px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.16), inset 0px 8px 24px -24px rgba(0, 0, 0, 0.02)',
  md: '0px 16px 20px rgba(0, 0, 0, 0.04), 0px 14px 16px rgba(0, 0, 0, 0.06), 0px 8px 8px rgba(0, 0, 0, 0.08), inset 0px 24px 24px -24px rgba(0, 0, 0, 0.02), 0px -8px 10px rgba(0, 0, 0, 0.04), 0px -7px 8px rgba(0, 0, 0, 0.06), 0px -4px 4px rgba(0, 0, 0, 0.08)',
  lg: '0px 26px 32px rgba(0, 0, 0, 0.04), 0px 24px 28px rgba(0, 0, 0, 0.04), 0px 16px 16px rgba(0, 0, 0, 0.08), 0px 12px 12px rgba(0, 0, 0, 0.08), inset 0px 8px 24px -24px rgba(0, 0, 0, 0.02)',
  banner: '0px 7px 32px 0px rgba(0, 0, 0, 0.35)',
} satisfies ThemeConfig['boxShadow'];

export const fontFamily = {
  ...tailwindConfig.fontFamily,
  mono: "'Roboto Mono', Monaco, Courier, monospace",
  regular: "'Inter', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
} satisfies ThemeConfig['fontFamily'];

export const sizes: SnorkelSizes = {
  0: '0rem',
  2: '0.125rem',
  4: '0.25rem',
  8: '0.5rem',
  12: '0.75rem',
  16: '1rem',
  24: '1.5rem',
  32: '2rem',
  40: '2.5rem',
  48: '3rem',
  56: '3.5rem',
  64: '4rem',
  72: '4.5rem',
  80: '5rem',
};

export default null;
