import * as Portal from '@radix-ui/react-portal';
import type { CSSProperties, PropsWithChildren } from 'react';
import React, { useState } from 'react';

import combineClasses from '@coral/utils/combineClasses';

import ConfirmCloseModal from './ConfirmCloseModal';

import useOnEscape from '../../hooks/useOnEscape';

// eslint-disable-next-line import/no-cycle

const DEFAULT_MODAL_WIDTH = 600;

type ModalItemProps = {
  width: number | string;
  margin: number | string;
  height?: CSSProperties['height'];
};

type ModalProps = {
  onClose: VoidFunction;
  children: React.ReactNode;
  confirmClose?: { text: string };
  isOpen?: boolean;
  flexes?: boolean;
  hasBackdrop?: boolean;
  dataCy?: string;
  shouldKeepModalMounted?: boolean; // Keep modal mounted when open is false
  shouldUsePortal?: boolean;
  rounded?: boolean;
} & Partial<ModalItemProps>;

type ModalContentProps = {
  modalItemStyles?: CSSProperties;
  onClick: VoidFunction;
} & Partial<ModalProps>;

const PortalWrapper = ({
  shouldUsePortal,
  children,
}: PropsWithChildren<{ shouldUsePortal: boolean }>) => {
  return shouldUsePortal ? (
    <Portal.Root>{children}</Portal.Root>
  ) : (
    <>{children}</>
  );
};

const ModalContent = ({
  children,
  isOpen,
  dataCy,
  modalItemStyles,
  flexes,
  hasBackdrop,
  rounded = true,
  onClick,
}: ModalContentProps) => {
  return (
    <div
      className={`fixed left-0 top-0 z-modal flex h-full w-full items-center justify-center overflow-y-auto ${
        isOpen ? '' : 'hidden'
      }`}
      data-cy={dataCy ?? 'modal'}
      role="dialog"
    >
      <div
        style={modalItemStyles}
        className={combineClasses(
          'relative z-modal overflow-y-auto bg-white shadow-lg',
          flexes ? 'flex flex-col' : '',
          rounded ? 'rounded-lg' : '',
        )}
        data-cy="modal-item"
      >
        {children}
      </div>
      <div
        className={`fixed left-0 top-0 z-overlay h-full w-full opacity-70 ${
          hasBackdrop ? 'bg-black' : ''
        }`}
        onClick={onClick}
        data-cy="modal-backdrop"
      />
    </div>
  );
};

/**
 * Renders a modal with an overlay. Closes upon clicking on cancel button, the overlay, or escape key.
 *
 * Caller is responsible for clearing state after submit. When the open prop is passed, <Modal/> will persist state
 * when closed by toggling CSS to avoid rerenders that would clear the state.
 * @param props
 * @returns <Modal>
 */
const Modal = ({
  onClose,
  confirmClose,
  children,
  width,
  margin,
  height,
  isOpen = true,
  flexes,
  hasBackdrop = true,
  dataCy,
  shouldKeepModalMounted = false,
  shouldUsePortal = false,
  rounded = true,
}: ModalProps) => {
  const [confirmCloseModalVisible, setConfirmCloseModalVisible] =
    useState<boolean>(false);

  const handleClose = () => {
    if (confirmClose) {
      setConfirmCloseModalVisible(true);

      return;
    }

    onClose();
  };

  const handleConfirmClose = () => {
    setConfirmCloseModalVisible(false);
  };

  useOnEscape(handleClose);

  const modalItemStyles: CSSProperties = {
    width: width || DEFAULT_MODAL_WIDTH,
    maxWidth: width || DEFAULT_MODAL_WIDTH,
    height: height || '',
    maxHeight: '95%',
    margin: margin || 0,
  };

  const modalContentProps = {
    isOpen,
    dataCy,
    flexes,
    hasBackdrop,
    modalItemStyles,
    rounded,
    onClick: handleClose,
  };

  return (
    <PortalWrapper shouldUsePortal={shouldUsePortal}>
      {shouldKeepModalMounted || isOpen ? (
        <>
          {confirmClose && confirmCloseModalVisible && (
            <div className="absolute z-modal">
              <PortalWrapper shouldUsePortal={shouldUsePortal}>
                <ModalContent
                  {...modalContentProps}
                  modalItemStyles={{ ...modalItemStyles, width: 300 }}
                >
                  <ConfirmCloseModal
                    onClose={handleConfirmClose}
                    onConfirm={onClose}
                    text={confirmClose.text}
                  />
                </ModalContent>
              </PortalWrapper>
            </div>
          )}
          <ModalContent {...modalContentProps}>{children}</ModalContent>
        </>
      ) : null}
    </PortalWrapper>
  );
};

export default Modal;
