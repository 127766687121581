import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Divider from '@coral/components/Divider';
import { Icons } from '@coral/components/Icon';
import Text from '@coral/components/Text';
import { isTruthy } from '@coral/utils/isTruthy';
import { ApplicationRoutes, LLM_FINE_TUNING_NODE } from '@core/constants';
import type { NodeDetails } from '@core/types';
import { AuthorizationWrapper } from '@global/AuthorizationWrapper';
import type { AppDetailsState } from '@global/state/reducers/appDetails/types';
import * as selectors from '@global/state/selectors';
import useHasAccessToFeature from '@hooks/fbac/useHasAccessToFeature';
import useCachedRequest from '@hooks/useCachedRequest';
import useDatasetId from '@hooks/useDatasetId';
import { useFlag } from '@hooks/useFlag';
import useGetRequestParams from '@hooks/useGetRequestParams';
import useNodeId from '@hooks/useNodeId';
import useSnorkelRouter from '@hooks/useSnorkelRouter';
import { datasetsApi } from '@utils/api/serverRequests';
import { Flags } from '@utils/getFlag';
import { validateRequestParam } from '@utils/validateRequestParams';

import CurrentAppNav from './CurrentAppNav';
import NavTitle from './NavTitle';
import NodeSelector from './NodeSelector';
import NotebookItem from './NotebookItem';
import calcAppNavItems from './utils/calcAppNavItems';
import calcAppNodeNavItems from './utils/calcAppNodeNavItems';
import { calcWorkflowNavItems } from './utils/calcWorkflowNavItems';
import WorkspaceSelector from './WorkspaceSelector';

import NavigationButton from '../common/NavigationButton';
import removeUrlParams from '../util/removeUrlParams';

// --------------------------------------------------------------------
type ItemConfig = Readonly<{
  tag: string;
  label: string;
  href: string;
  icon: Icons;
  cy: string;
}>;

export const items: ItemConfig[] = [
  {
    tag: 'home',
    label: 'Home',
    href: '/',
    icon: Icons.HOUSE,
    cy: 'home-page-navigation-button',
  },
  {
    tag: 'files',
    label: 'Files',
    href: '/files',
    icon: Icons.DOCUMENTATION,
    cy: 'files-navigation-button',
  },
  {
    tag: 'datasets',
    label: 'Datasets',
    href: '/datasets',
    icon: Icons.DATASET,
    cy: 'datasets-navigation-button',
  },
  {
    tag: 'applications',
    label: 'Applications',
    href: '/applications',
    icon: Icons.OVERVIEW,
    cy: 'applications-navigation-button',
  },
  {
    tag: 'prompts',
    label: 'Prompts',
    href: `/prompts`,
    icon: Icons.BRAIN,
    cy: 'prompts-navigation-button',
  },
  {
    tag: 'deployments',
    label: 'Deployments',
    href: '/deployments',
    icon: Icons.DEPLOY,
    cy: 'deployments-navigation-button',
  },
];

type ItemProps = {
  path: string;
  config: ItemConfig;
};

const Item = ({ path, config: { label, href, icon, cy } }: ItemProps) => (
  <NavigationButton href={href} selected={path === href} leftIcon={icon}>
    <span data-cy={cy} className="flex-1 truncate py-1">
      {label}
    </span>
  </NavigationButton>
);

// --------------------------------------------------------------------
const myWorkItem: ItemConfig = {
  tag: 'work',
  label: 'Annotation batches',
  href: '/work',
  icon: Icons.CHECKMARK__IN_CIRCLE,
  cy: 'my-work-nav-button',
};

type MyWorkItemProps = Readonly<{
  path: string;
}>;

const MyWorkItem = ({ path }: MyWorkItemProps) => (
  <Item key={myWorkItem.href} path={path} config={myWorkItem} />
);

// --------------------------------------------------------------------
const calcApplicationId = (
  appId: number | undefined,
  path: string,
): number | null => {
  if (appId) {
    return appId;
  }

  const newAppPathMatch = path.match(
    /^\/applications\/new\/(app|dataset)\/([0-9]+)$/,
  );

  if (newAppPathMatch && newAppPathMatch[2]) {
    return Number(newAppPathMatch[2]);
  }

  return null;
};

const calcModelNodeIdFromApp = (application?: AppDetailsState): number => {
  const { dag: nodes = {} } = application || {};
  const modelNodeKeys: Number[] = Object.keys(
    nodes as Record<string, NodeDetails>,
  )
    .filter(nodeId => nodes[nodeId].expected_op_type === 'Model')
    .reduce((result: Number[], nodeId) => {
      result.push(Number(nodeId));

      return result;
    }, []);

  if (modelNodeKeys.length === 1) {
    return Number(modelNodeKeys[0]);
  }

  return 0;
};

const calcModelNodes = (
  dagNodes: Record<string, NodeDetails>,
): NodeDetails[] => {
  return Object.values(dagNodes).filter(
    node => node.expected_op_type === 'Model',
  );
};

// --------------------------------------------------------------------
const NavigationSidebarNav = () => {
  const router = useSnorkelRouter();
  const { asPath, query } = router;

  const { application_uid } = useGetRequestParams();
  const datasetUid = useDatasetId();
  const { data: dataset } = useCachedRequest(
    datasetsApi.fetchDatasetByUidDatasetsDatasetUidGet,
    { datasetUid: datasetUid! },
    { suspendFetch: !datasetUid },
  );
  const appId = calcApplicationId(application_uid, router.asPath);
  const application = useSelector(selectors.appDetails.selectAll);
  const promptWorkflow = useSelector(
    selectors.navigation.selectMetaPromptWorkflow,
  );

  const path = removeUrlParams(asPath);

  const appDataExists =
    application && !isEmpty(application) && application.id === appId;
  const { dag: nodes = {} } = application || {};

  // 1. Get nodeId from path (via router params) first.
  // 2. If not there, try useNodeId which reads from redux store.
  // 3. If not there, find model node from `application`.
  //    - cases 1 & 2 may not have node-id on the new/app/<app-id> page,
  //      on a fresh page load, where data is not populated in redux yet
  const routeNodeId = validateRequestParam(query.nodeId);
  const nodeIdFromStore = useNodeId();
  const nodeId =
    routeNodeId || nodeIdFromStore || calcModelNodeIdFromApp(application);
  const [selectedNodeId, setSelectedNodeId] = useState<number>(nodeId);

  useEffect(() => {
    setSelectedNodeId(nodeId);
  }, [nodeId]);

  // get selected node from application data, or from nodeDetails in redux
  const savedNodeDetails = useSelector(
    selectors.nodeDetails.selectNodeDetailsNode,
  );
  const workspaces = useSelector(selectors.workspaces.selectWorkspaces);

  const node = selectedNodeId ? nodes[selectedNodeId] : savedNodeDetails;
  const isLLMFineTuningNode = node?.node_cls === LLM_FINE_TUNING_NODE; // used to show/hide the evaluate link

  const { hasAccessToFeature: areDeploymentsEnabled } =
    useHasAccessToFeature('deployments');

  const { onboarding_settings } = node?.node_config || {};
  const isOnboardingComplete = isTruthy(onboarding_settings);
  const isAppPage = !!asPath.match(/^\/applications\/.+/);
  const isPromptDevelopmentEnabled = useFlag(
    Flags.EXPERIMENTAL_PROMPT_DEVELOPMENT,
  );

  const isAppSetupPage = !!asPath.match(/^\/applications\/new\/app\/[0-9]+$/);

  const appNavItems =
    isAppPage && application
      ? calcAppNavItems({
          application,
          isOnboardingComplete,
          path,
        })
      : [];

  const isPromptPage = !!asPath.match(/^\/prompts\/.+/);
  const workflowNavItems =
    isPromptDevelopmentEnabled && isPromptPage && promptWorkflow
      ? calcWorkflowNavItems({ promptWorkflow, path })
      : [];

  const nodeNavItems =
    (isAppPage || isAppSetupPage) && application
      ? calcAppNodeNavItems({
          application,
          nodeId: selectedNodeId,
          dataset,
          path,
          isLLMFineTuningNode,
        })
      : [];

  const shouldShowNodeSelector =
    isAppPage &&
    application &&
    application.isSetupComplete &&
    calcModelNodes(nodes).length > 1;

  const enabledItems = useMemo(() => {
    return items.filter(item => {
      if (item.tag === 'prompts') {
        return isPromptDevelopmentEnabled;
      }

      if (item.tag === 'deployments') {
        return areDeploymentsEnabled;
      }

      return true;
    });
  }, [areDeploymentsEnabled, isPromptDevelopmentEnabled]);

  const handleNodeChange = (nextNodeId: number) => {
    setSelectedNodeId(nextNodeId);

    // Use current path to determine where to navigate to.
    // If on a node page already, maintain the page but switch nodes.
    // If on the app/pipeline/dag page, navigate to the develop page
    if (path.match(/^\/applications\/[0-9]+\/nodes\/[0-9]+/)) {
      // on a node-specific page: navigate to the same page with the new node
      router.push(path.replace(/nodes\/[0-9]+/, `nodes/${nextNodeId}`));
    } else {
      router.push(
        `/applications/${application.id}/nodes/${nextNodeId}/${ApplicationRoutes.DEVELOP}`,
      );
    }
  };

  return (
    <div className="mt-4 flex-1">
      {workspaces.length > 0 ? (
        <>
          <WorkspaceSelector />
          {enabledItems.map(config => (
            <Item key={config.href} path={path} config={config} />
          ))}
          <AuthorizationWrapper requiredFeature="notebook">
            <NotebookItem />
          </AuthorizationWrapper>
          <MyWorkItem path={path} />
          {appDataExists || workflowNavItems.length > 0 ? (
            <Divider className="my-3" />
          ) : null}
          <nav className="flex flex-col gap-y-2">
            {appDataExists ? (
              <>
                <NavTitle title={application.name} />
                <ul className="flex flex-col gap-y-2">
                  <CurrentAppNav navItems={appNavItems} />
                  {shouldShowNodeSelector ? (
                    <NodeSelector
                      application={application}
                      value={selectedNodeId}
                      onNodeChange={handleNodeChange}
                    />
                  ) : null}
                  {nodeNavItems.length > 0 ? (
                    <CurrentAppNav navItems={nodeNavItems} />
                  ) : null}
                </ul>
              </>
            ) : null}
            <ul className="flex flex-col gap-y-2">
              {promptWorkflow && workflowNavItems.length > 0 ? (
                <>
                  <NavTitle title={promptWorkflow.name} />
                  <CurrentAppNav navItems={workflowNavItems} />
                </>
              ) : null}
            </ul>
          </nav>
        </>
      ) : (
        <Text className="px-2">No Workspace Found</Text>
      )}
    </div>
  );
};

export default NavigationSidebarNav;
