import type {
  SnorkelBrandColorPalette,
  SnorkelPalette,
  SnorkelProductColorPalette,
} from '@snorkel-themes/themes/types';

// https://www.figma.com/design/3dFliOFNrWzkQhGnahbN5S/Elements?node-id=130-1&t=8AdSaoIyCjiSpr5D-1
const productPalette: SnorkelProductColorPalette = {
  white: '#ffffff',
  black: '#000000',
  nauticalBlack: '#121221',
  squidInk: '#20202f',
  squidPencil: '#2b2b36',
  blackAlpha: {
    2: '#00000005',
    4: '#0000000a',
    6: '#0000000f',
    8: '#00000014',
    10: '#0000001a',
    12: '#0000001f',
    14: '#00000024',
    20: '#00000033',
    30: '#0000004d',
    40: '#00000066',
    50: '#00000080',
    60: '#00000099',
    70: '#000000b2',
    80: '#000000cc',
    90: '#000000e5',
    94: '#000000f0',
  },
  blue: {
    20: '#fafcff',
    50: '#f5faff',
    100: '#edf5ff',
    200: '#d0e2ff',
    300: '#a6c8ff',
    400: '#78a9ff',
    500: '#4589ff',
    600: '#0f62fe',
    700: '#0043ce',
    800: '#002d9c',
    900: '#001d6c',
    1000: '#001141',
  },
  cyan: {
    20: '#fafcff',
    50: '#edf7ff',
    100: '#e5f6ff',
    200: '#bae6ff',
    300: '#82cfff',
    400: '#33b1ff',
    500: '#1192e8',
    600: '#0072c3',
    700: '#00539a',
    800: '#003a6d',
    900: '#012749',
    1000: '#061727',
  },
  gray: {
    20: '#f8f8f8',
    50: '#f0f0f0',
    100: '#f4f4f4',
    200: '#e0e0e0',
    300: '#c6c6c6',
    400: '#a8a8a8',
    500: '#8d8d8d',
    600: '#6f6f6f',
    700: '#525252',
    800: '#393939',
    900: '#262626',
    1000: '#161616',
  },
  green: {
    20: '#f0fcf2',
    50: '#e0fae6',
    100: '#defbe6',
    200: '#a7f0ba',
    300: '#6fdc8c',
    400: '#42be65',
    500: '#24a148',
    600: '#198038',
    700: '#0e6027',
    800: '#044317',
    900: '#022d0d',
    1000: '#071908',
  },
  magenta: {
    20: '#fffcfc',
    50: '#fff2f7',
    100: '#fff0f7',
    200: '#ffd6e8',
    300: '#ffafd2',
    400: '#ff7eb6',
    500: '#ee5396',
    600: '#d12771',
    700: '#9f1853',
    800: '#740937',
    900: '#510224',
    1000: '#2a0a18',
  },
  nauticalBlackAlpha: {
    2: '#12122105',
    4: '#1212210a',
    6: '#1212210f',
    8: '#12122114',
    10: '#1212211a',
    12: '#1212211f',
    14: '#12122124',
    20: '#12122133',
    30: '#1212214d',
    40: '#12122166',
    50: '#12122180',
    60: '#12122199',
    70: '#121221b2',
    80: '#121221cc',
    90: '#121221e5',
    94: '#121221f0',
  },
  orange: {
    100: '#fff2e8',
    200: '#ffd9be',
    300: '#ffb784',
    400: '#ff832b',
    500: '#eb6200',
    600: '#ba4e00',
    700: '#8a3800',
    800: '#5e2900',
    900: '#3e1a00',
    1000: '#231000',
  },
  purple: {
    100: '#f6f2ff',
    200: '#e8daff',
    300: '#d4bbff',
    400: '#d4bbff',
    500: '#a56eff',
    600: '#8a3ffc',
    700: '#6929c4',
    800: '#491d8b',
    900: '#31135e',
    1000: '#1c0f30',
  },
  red: {
    2: '#fffcfc',
    50: '#fff7f7',
    100: '#fff1f1',
    200: '#ffd7d9',
    300: '#ffb3b8',
    400: '#ff8389',
    500: '#fa4d56',
    600: '#da1e28',
    700: '#a2191f',
    800: '#750e13',
    900: '#520408',
    1000: '#2d0709',
  },
  squidInkAlpha: {
    2: '#20202f05',
    4: '#20202f0a',
    6: '#20202f0f',
    8: '#20202f14',
    10: '#20202f1a',
    12: '#20202f1f',
    14: '#20202f24',
    20: '#20202f33',
    30: '#20202f4d',
    40: '#20202f66',
    50: '#20202f80',
    60: '#20202f99',
    70: '#20202fb2',
    80: '#20202fcc',
    90: '#20202fe5',
    94: '#20202ff0',
  },
  surfAlpha: {
    0: '#f8f8f800',
    2: '#f8f8f805',
    4: '#f8f8f80a',
    6: '#f8f8f80f',
    8: '#f8f8f814',
    10: '#f8f8f81a',
    12: '#f8f8f81f',
    14: '#f8f8f824',
    20: '#f8f8f833',
    30: '#f8f8f84d',
    40: '#f8f8f866',
    50: '#f8f8f880',
    60: '#f8f8f899',
    70: '#f8f8f8b2',
    80: '#f8f8f8cc',
    90: '#f8f8f8e5',
    94: '#f8f8f8f0',
  },
  teal: {
    100: '#d9fbfb',
    200: '#9ef0f0',
    300: '#3ddbd9',
    400: '#08bdba',
    500: '#009d9a',
    600: '#007d79',
    700: '#005d5d',
    800: '#004144',
    900: '#022b30',
    1000: '#081a1c',
  },
  whiteAlpha: {
    2: '#ffffff05',
    4: '#ffffff0a',
    6: '#ffffff0f',
    8: '#ffffff14',
    10: '#ffffff1a',
    12: '#ffffff1f',
    14: '#ffffff24',
    20: '#ffffff33',
    30: '#ffffff4d',
    40: '#ffffff66',
    50: '#ffffff80',
    60: '#ffffff99',
    70: '#ffffffb2',
    80: '#ffffffcc',
    90: '#ffffffe5',
    94: '#fffffff0',
  },
  yellow: {
    100: '#fcf4d6',
    200: '#fddc69',
    300: '#f1c21b',
    400: '#ddaa11',
    500: '#b28600',
    600: '#8e6a00',
    700: '#684e00',
    800: '#483700',
    900: '#302400',
    1000: '#1c1500',
  },
  highlighter: {
    yellow: '#FDFF32',
    greenYellowLight: '#E9FF32',
    greenYellow: '#D4FF32',
    greenLight: '#ABFF32',
    greenMed: '#8CFF32',
    greenDark: '#10FF00',
    blueGreen: '#00FFD9',
    red: '#FF0000',
    fuchsia: '#FF00DB',
    purple: '#BD00FF',
  },
};

const brandPalette: SnorkelBrandColorPalette = {
  pink: '#e3057d',
  pinkHover: '#b60464',
  ink: '#14194d',
  marine: '#3ea2f9',
  surf: '#f8f8f8',
};

const palette: SnorkelPalette = {
  ...productPalette,
  ...brandPalette,
};

export default palette;
