import type { Ref } from 'react';
import React from 'react';

import type {
  DropdownOptionType,
  OnChangeSearchValue,
  ToggleDropdown,
} from './types';

import Chip from '../Chip';
import Input from '../Input';
import getHeightClass, { HeightSizes } from '../Input/utils/getHeightClass';
import getInputStyles from '../Input/utils/getInputStyles';
import type { InputSizes } from '../InputContainer/types';

type DropdownEntryInputProps<TValue> = {
  id?: string;
  dataCy?: string;
  className?: string;
  required?: boolean;
  toggleDropdown: ToggleDropdown;
  searchValue: string;
  placeholderValue?: string;
  onChangeSearchValue: OnChangeSearchValue;
  selectedOptions: DropdownOptionType<TValue>[];
  multiSelect: boolean;
  disabled?: boolean;
  entryRef: Ref<HTMLInputElement>;
  size: InputSizes;
  customButtonContentRender?: (
    value: DropdownOptionType<TValue>[],
  ) => React.ReactNode;
};

function DropdownEntryInput<TValue>(props: DropdownEntryInputProps<TValue>) {
  const {
    id,
    dataCy,
    entryRef,
    className,
    required,
    onChangeSearchValue,
    toggleDropdown,
    searchValue,
    disabled,
    selectedOptions,
    placeholderValue,
    multiSelect,
    size,
    customButtonContentRender,
  } = props;

  if (!multiSelect) {
    return (
      <Input
        name={id}
        id={id}
        data-cy={`dropdown-entry-input-${dataCy || id}`}
        fullWidth
        disabled={disabled}
        ref={entryRef}
        className={className}
        required={required}
        autoComplete="off"
        aria-haspopup="listbox"
        onClick={toggleDropdown}
        value={searchValue}
        placeholder={placeholderValue}
        onChange={onChangeSearchValue}
        size={size}
        prefixEl={
          customButtonContentRender &&
          customButtonContentRender(selectedOptions)
        }
      />
    );
  }

  return (
    <div
      data-cy="multi-select-combobox"
      className={`flex flex-wrap items-center gap-2 rounded border border-gray-border bg-white py-1 pl-1 pr-3 focus-within:border-primary ${getHeightClass(
        HeightSizes[size],
      )} ${getInputStyles({ error: false, size })}`}
    >
      {selectedOptions.length > 0 &&
        selectedOptions.map(({ title, value }) => (
          <Chip key={((value as unknown as string) || '').toString()}>
            {title}
          </Chip>
        ))}
      <input
        onClick={toggleDropdown}
        value={searchValue}
        placeholder={placeholderValue}
        onChange={onChangeSearchValue}
        className="h-full flex-1 rounded pl-2 text-sm focus:border-transparent focus:outline-none"
      />
    </div>
  );
}

export default DropdownEntryInput;
