import type {
  SearchBarState,
  SearchTemplate,
} from '@app/StudioPage/components/SearchBar';
import type { SearchInputState } from '@app/StudioPage/state/searchInput/types';
import type { Label } from '@core/types';
import type { WorkspaceType } from '@global/state/reducers/workspaces/types';
import type { GraphStructure } from '@hooks/useGraph/types';

export enum StudioViewModes {
  DOCUMENT_VIEW = 'document',
  RECORD_VIEW = 'record',
  GALLERY_VIEW = 'gallery',
  IMAGE_VIEW = 'image',
  GRID_VIEW = 'grid',
  TABLE_VIEW = 'table',
  RAW = 'raw',
  SEARCH_VIEW = 'search',
  PAGE_VIEW = 'page',
}

export type FeedbackResponse = {
  score: number;
  text: string;
};

export type SuggestionType = {
  msg: string;
  key?: string;
  lvl: number;
  lf_names?: string[];
  page?: string;
  page_options?: null;
};

export type Value = null | string | number | boolean | undefined;

export type ViewOption<T = Value> = {
  uid: string;
  title: string;
  choices: null | string[] | number[];
  onChange: (value?: T) => void;
  value?: T;
  'data-cy': string;
};

export type TemplateCollection = {
  combiner: string;
  graph?: GraphStructure;
  currValidConfigs: SearchTemplate[];
};

export type ValidSearch = {
  config: Record<string, any>[];
  graph: GraphStructure;
  label?: Label;
  isInvalidSearch: boolean;
};

export type SearchBarStateAndInputData = {
  searchBarState: SearchBarState;
  searchInputData: SearchInputState;
};

export type StudioPageProps = {
  mode?: string;
  page?: number;
  workspace: WorkspaceType;
};

export default null;
