import type { PropsWithChildren } from 'react';

import { useAlertTriggerContext } from './Alert/useAlertContext';
import PageRegionContent from './PageRegionContent';

export type PageRegionContainerProps = PropsWithChildren<{
  tag: string;
  wrapperClasses?: string;
}>;

const PageRegionContainer = ({
  tag,
  children,
  wrapperClasses,
}: PageRegionContainerProps) => {
  const { errorAlertData, resetError } = useAlertTriggerContext();

  return (
    <PageRegionContent
      tag={tag}
      errorAlertData={errorAlertData}
      resetError={resetError}
      wrapperClasses={wrapperClasses}
    >
      {children}
    </PageRegionContent>
  );
};

export default PageRegionContainer;
