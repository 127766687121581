import { useRef } from 'react';

import type { JobInfo } from '@api/tdm';
import { JobState } from '@api/tdm';
import useCachedRequest from '@hooks/useCachedRequest';
import { jobsApi } from '@utils/api/serverRequests';

export type UsePollerProps = {
  onStopPolling?: () => void;
  onChangeStatus?: (value: Partial<JobInfo>) => void;
  interval?: number;
  jobUid: string | null;
  requestOptions?: Partial<Parameters<typeof useCachedRequest>[1]>;
};

const pollInterval = 2_000;

const useCachedJobPoller = ({
  jobUid,
  interval: intervalProp,
  onStopPolling,
  onChangeStatus,
  requestOptions,
}: UsePollerProps) => {
  const prevJobUid = useRef<string | null>(jobUid);
  const isPolling = useRef<boolean>(true);
  const interval = intervalProp ?? pollInterval;

  if (jobUid && prevJobUid.current !== jobUid) {
    isPolling.current = true;
    prevJobUid.current = jobUid;
  }

  const {
    data: status,
    isLoading,
    mutate,
  } = useCachedRequest(
    jobsApi.getJobForUidJobsJobUidGet,
    { jobUid: jobUid! },
    {
      ...(requestOptions ?? {}),
      suspendFetch: !jobUid,
      useSWROptions: {
        refreshInterval() {
          if (interval && isPolling.current) {
            return interval;
          }

          return 0;
        },
      },
      onSuccess: onChangeStatus,
    },
  );

  if (isPolling && status && status.state !== JobState.Running) {
    isPolling.current = false;
    onStopPolling?.();
  }

  const stopPolling = () => {
    isPolling.current = false;
    onStopPolling?.();
  };

  return {
    status,
    stopPolling,
    isLoading,
    isPolling: isPolling.current,
    mutateJobPoller: mutate,
  };
};

export default useCachedJobPoller;
