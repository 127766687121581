import HStack, { HStackAlignment, HStackSpacing } from '../../../layout/HStack';
import combineClasses from '../../../utils/combineClasses';
import Icon, { Icons } from '../../Icon';
import Spinner from '../../Spinner';
import Text, { TextSize, TextVariant, TextWeight } from '../../Text';
import { TextColor } from '../../Text/types';
import type { OnboardingAccordionProps } from '../types';

const OnboardingAccordion = ({
  summary,
  children,
  isOpen = false,
  setIsOpen,
  withHeaderSpinner = false,
  tooltipTitle,
  isDisabled = false,
  summaryStatus,
  dataCy = 'onboarding-accordion-details',
  ...props
}: OnboardingAccordionProps) => {
  const withSpinner = Boolean(withHeaderSpinner && !isOpen && !isDisabled);

  const onToggle = (e: React.MouseEvent<HTMLDetailsElement>) => {
    e.stopPropagation();
    const target = e.target as HTMLDetailsElement;

    if (isDisabled) return;

    if (target.open !== isOpen && setIsOpen) {
      setIsOpen(target.open);
    }
  };

  return (
    <details open={isOpen} {...props} onToggle={onToggle} data-cy={dataCy}>
      <summary
        className={combineClasses(
          'flex cursor-pointer select-none items-center justify-between p-3 focus:outline-none',
          isOpen ? 'rounded-t bg-coolGray-200' : 'rounded',
        )}
        data-cy="onboarding-accordion-header"
      >
        <HStack alignment={HStackAlignment.CENTER} spacing={HStackSpacing.xs}>
          <Icon
            name={Icons.CHEVRON__RIGHT}
            className={isOpen ? 'rotate-90' : ''}
          />
          <Text
            weight={TextWeight.semibold}
            size={TextSize.md}
            variant={TextVariant.subtitle}
            className={isDisabled ? TextColor.DISABLED : ''}
          >
            {summary}
          </Text>
          {withSpinner && (
            <div className="ml-1">
              <Spinner title={tooltipTitle} />
            </div>
          )}
        </HStack>
        {!isOpen && !isDisabled && <div>{summaryStatus}</div>}
      </summary>
      <div className="box-border bg-coolGray-100">{children}</div>
    </details>
  );
};

export default OnboardingAccordion;
