import type { HTMLAttributes, MouseEvent, ReactNode, Ref } from 'react';
import React, { forwardRef } from 'react';

import type { IconColor, Icons } from '../../components/Icon';
import Icon from '../../components/Icon';
import Tooltip from '../../components/tooltips/TooltipV2';
import combineClasses from '../../utils/combineClasses';

export enum IconButtonSizes {
  default = 'default',
  large = 'large',
  small = 'small',
  Xsmall = 'x-small',
}

export enum IconSizes {
  default = 'default',
  large = 'large',
  small = 'small',
  Xsmall = 'x-small',
}

type IconButtonChildrenOptions =
  | { children: ReactNode; icon?: never }
  | { children?: never; icon: Icons };

interface IconButtonOptions extends HTMLAttributes<HTMLButtonElement> {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  active?: boolean;
  size?: IconButtonSizes;
  iconSize?: IconSizes;
  submit?: boolean;
  tooltip?: string;
  href?: string;
  'data-cy'?: string;
  iconColor?: IconColor;
}

export type IconButtonProps = IconButtonOptions & IconButtonChildrenOptions;

const IconButton = (
  props: IconButtonProps,
  forwardedRef: Ref<HTMLButtonElement>,
) => {
  const {
    children,
    icon,
    onClick,
    disabled,
    tooltip,
    href,
    active,
    className,
    size = IconButtonSizes.default,
    iconSize = IconSizes.default,
    submit,
    iconColor,
    ...otherProps
  } = props;

  const activeClasses = active
    ? 'border-cyan-300 bg-cyan-100 text-blue-600'
    : 'border-transparent text-gray-600';

  const getButtonSize = () => {
    switch (size) {
      case IconButtonSizes.small:
        return 'w-smallButton h-smallButton';
      case IconButtonSizes.Xsmall:
        return 'size-5';
      case IconButtonSizes.large:
        return 'size-10';
      default:
        return 'size-8';
    }
  };

  const getIconSize = () => {
    switch (iconSize) {
      case IconSizes.small:
        return 'size-2.5';
      case IconSizes.Xsmall:
        return 'size-3';
      case IconSizes.large:
        return 'size-5';
      default:
        return 'size-4';
    }
  };

  const renderIcon = icon ? (
    <Icon
      name={icon}
      className={combineClasses('m-auto', getIconSize())}
      disabled={disabled}
      color={iconColor}
    />
  ) : (
    children
  );

  const renderButton = () => {
    return (
      <button
        ref={forwardedRef}
        type={submit ? 'submit' : 'button'}
        disabled={disabled}
        {...(typeof active === 'boolean' ? { 'aria-selected': active } : {})}
        aria-disabled={disabled}
        onClick={onClick}
        className={combineClasses(
          getButtonSize(),
          'flex-none rounded border text-sm',
          disabled
            ? 'cursor-not-allowed text-gray-100'
            : 'hover:border-cyan-300 hover:bg-cyan-200',
          activeClasses,
          className,
        )}
        {...otherProps}
      >
        {href ? (
          <a href={href} target="_blank" rel="noreferrer">
            {renderIcon}
          </a>
        ) : (
          renderIcon
        )}
      </button>
    );
  };

  return tooltip ? (
    <Tooltip title={tooltip} {...otherProps}>
      {renderButton()}
    </Tooltip>
  ) : (
    renderButton()
  );
};

export default forwardRef(IconButton);
