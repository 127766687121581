/* eslint-disable local-rules/require-data-cy */
import type { MouseEventHandler, ReactNode } from 'react';

import NotebookIframe from '@app/StudioPage/components/NotebookIframe';
import NotebookLeftToolbar from '@components/NotebookLeftToolbar';
import Button, { ButtonThemes } from '@coral/components/Button';
import Icon, { Icons } from '@coral/components/Icon';
import Modal from '@coral/components/Modal';
import { USER_GUIDE_PYTHON_SDK_URL } from '@core/constants';
import useNotebookState from '@hooks/useNotebookState';

type NotebookButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
};

type NotebookModalProps = {
  button?: (props: NotebookButtonProps) => ReactNode;
};

const NotebookModalButton = ({ button }: NotebookModalProps) => {
  const {
    handleOpen,
    handleClose,
    handleFileBrowserClick,
    copyNotebookUrl,
    key,
    url,
    open,
    isDisabled,
    isLoading,
  } = useNotebookState();

  return (
    <>
      {button ? (
        button({ onClick: handleOpen })
      ) : (
        <Button
          theme={ButtonThemes.secondary}
          className="bg-black"
          border={false}
          onClick={handleOpen}
        >
          <Icon name={Icons.NOTEBOOK} className="mr-2 text-gray-300" /> Notebook
        </Button>
      )}
      <Modal
        isOpen={open}
        onClose={handleClose}
        width={1920}
        margin={23}
        height="100%"
        shouldKeepModalMounted
        shouldUsePortal
      >
        <div className="flex h-full flex-1 flex-col bg-white">
          <div className="flex h-12 items-center justify-between border-b border-gray-300 bg-gray-100">
            <div className="flex items-center">
              <div className="flex items-center border-r px-4 text-base text-gray-700">
                Notebook
              </div>
              <NotebookLeftToolbar />
            </div>
            <div className="flex items-center">
              <Button href={url} border={false} id="notebook-button" openInTab>
                <Icon name={Icons.NOTEBOOK} className="mr-2" />
                Open in New Tab
              </Button>
              <Button
                onClick={() => copyNotebookUrl()}
                border={false}
                id="copy-notebook-url"
              >
                <Icon name={Icons.COPY__FILE} className="mr-2" />
                Copy URL
              </Button>
              <Button
                href={USER_GUIDE_PYTHON_SDK_URL}
                border={false}
                id="docs"
                openInTab
              >
                <Icon name={Icons.DOCUMENTATION} className="mr-2" />
                SDK Docs
              </Button>
              <Button onClick={handleFileBrowserClick} border={false}>
                <Icon name={Icons.FOLDERS} className="mr-2" />
                File Browser
              </Button>
              <button
                type="button"
                className="flex h-full w-12 items-center justify-center border-l"
                onClick={handleClose}
              >
                <Icon name={Icons.X} />
              </button>
            </div>
          </div>
          <div className="flex-1 overflow-hidden">
            {!isDisabled && !isLoading && (
              <NotebookIframe url={url} iframeKey={String(key)} />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NotebookModalButton;
