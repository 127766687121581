import { theme } from '@snorkel-themes/themes/classic';

const { colors } = theme;

export const DEFAULT_MULTI_LABEL_KEY = '_default';
export const UNKNOWN_CLASS_LABEL = -1;
export const DEFAULT_NEGATIVE_LABEL = 'OTHER';
export const SPAN_ANNOTATION_POPUP_WIDTH = 288;
export const NUM_SEARCHABLE_COLUMNS = 10;

export const PRIMARY_COLOR = colors.primary;
export const SECONDARY_COLOR = colors.secondary;
export const TERTIARY_COLOR = colors.tertiary;
export const BLUE_700 = colors.blue[700];
export const GRAY_900 = colors.gray[900];
export const TEXT_GRAY_600 = colors.gray[600];
export const GRAY_400 = colors.gray[400];
export const GRAY_300 = colors.gray[300];
export const TEXT_GRAY_200 = colors.gray[200];
export const GRAY_100 = colors.gray[100];
export const YELLOW_500 = colors.yellow[500];
export const GREEN_TEXT = colors.green[500];
export const BORDER_COLOR = colors.gray.border;
export const WHITE = colors.white;

export default null;
export const UNKNOWN_LABEL = 'UNKNOWN';
