import { useSelector } from 'react-redux';

import MenuButton from '@components/Form/MenuButton';
import Icon from '@coral/components/Icon';
import Icons from '@coral/components/Icon/icons';
import * as selectors from '@global/state/selectors';
import useGlobalPreferences from '@hooks/useGlobalPreferences';
import useSnorkelRouter from '@hooks/useSnorkelRouter';
import { validateRequestParam } from '@utils/validateRequestParams';

import SidebarMenuItem from './common/SidebarMenuItem';
import DocumentationMenu from './DocumentationMenu';
import LicenseWarnings from './LicenseWarnings';
import NotificationsMenu from './NotificationsMenu';
import UserMenu from './UserMenu';

export const ROUTES_SUPPORTING_WORKSPACE_SWITCHING = [
  '',
  'applications',
  'datasets',
  'files',
  'deployments',
];

const itemClassName = 'flex items-center justify-between gap-3 px-2 -my-1';
const activeItemClassName = `${itemClassName} bg-gray-100 font-semibold`;

// --------------------------------------------------------------------
const NotificationsItem = () => {
  const { enableNotifications } = useGlobalPreferences();

  return enableNotifications ? (
    <MenuButton
      menuProps={{
        elevation: 0,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transformOrigin: { vertical: 'top', horizontal: 'left' },
      }}
      menu={<NotificationsMenu />}
      button={
        <SidebarMenuItem
          className={itemClassName}
          data-cy="documentation-button"
        >
          <Icon name={Icons.NOTIFICATION} />
          <span data-cy="notifications-menu-button" className="flex-1 truncate">
            Notifications
          </span>
        </SidebarMenuItem>
      }
    />
  ) : null;
};

// --------------------------------------------------------------------
const DocumentationItem = () => (
  <MenuButton
    menuProps={{
      elevation: 0,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      transformOrigin: { vertical: 'top', horizontal: 'left' },
    }}
    menu={<DocumentationMenu />}
    button={
      <SidebarMenuItem className={itemClassName} data-cy="documentation-button">
        <Icon name={Icons.HELP} />
        <span className="flex-1 truncate">Documentation</span>
      </SidebarMenuItem>
    }
  />
);

// --------------------------------------------------------------------
const UserItem = () => {
  const { username } = useSelector(selectors.auth.selectUser);
  const { asPath } = useSnorkelRouter();
  const path = asPath.split('?')[0];
  const isActive = path === '/admin/settings' || path === '/user/settings';

  return (
    <MenuButton
      menuProps={{
        elevation: 0,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transformOrigin: { vertical: 'top', horizontal: 'left' },
      }}
      menu={<UserMenu />}
      button={
        <SidebarMenuItem
          className={isActive ? activeItemClassName : itemClassName}
          data-cy="user-settings-button"
        >
          <Icon name={Icons.USER} />
          <span className="flex-1 truncate">{username}</span>
        </SidebarMenuItem>
      }
    />
  );
};

// --------------------------------------------------------------------
const NavigationSidebarFooter = () => {
  const userUid = useSelector(selectors.auth.selectUserUid);

  return (
    <ul className="mb-4 flex flex-col gap-1">
      <LicenseWarnings />
      <DocumentationItem />
      <NotificationsItem />
      {validateRequestParam(userUid) ? <UserItem /> : null}
    </ul>
  );
};

export default NavigationSidebarFooter;
