import * as Accordion from '@radix-ui/react-accordion';
import { useState } from 'react';

import combineClasses from '@coral/utils/combineClasses';

import Icon, { Icons } from '../../Icon';
import type {
  DefaultAccordionIconProps,
  DefaultAccordionProps,
} from '../types';

const DEFAULT_ACCORDION_ID = 'item-1';

export const AccordionDefaultIcon = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <Icon name={Icons.CHEVRON__RIGHT} className={isOpen ? 'rotate-90' : ''} />
  );
};

const defaultIconProps: DefaultAccordionIconProps = {
  position: 'end',
  Component: AccordionDefaultIcon,
};

const DefaultAccordion = ({
  summary,
  children,
  divideSummary,
  onClick,
  open,
  enablePadding = true,
  'data-cy': dataCy,
  iconProps: iconPropsInput,
}: DefaultAccordionProps) => {
  const iconProps = {
    ...defaultIconProps,
    ...(iconPropsInput ?? {}),
  };

  const controlledDefaultValue = open ? DEFAULT_ACCORDION_ID : undefined;

  const [internalValue, setInternalValue] = useState<string | undefined>(
    controlledDefaultValue,
  );

  return (
    <Accordion.Root
      data-cy="default-accordion-root"
      type="single"
      defaultValue={internalValue}
      collapsible
      value={internalValue}
      onValueChange={value => {
        setInternalValue(value);
        onClick?.();
      }}
    >
      <Accordion.Item
        data-cy="default-accordion-item"
        value={DEFAULT_ACCORDION_ID}
      >
        <Accordion.Trigger
          data-cy={dataCy ?? 'default-accordion-trigger'}
          className={combineClasses(
            iconProps.position === 'start'
              ? 'justify-start'
              : 'justify-between',
            'flex w-full cursor-pointer items-center px-4 py-2',
          )}
        >
          {iconProps.position === 'start' ? (
            <>
              <iconProps.Component
                isOpen={internalValue === DEFAULT_ACCORDION_ID}
              />
              <div className="default-accordion-summary font-medium">
                {summary}
              </div>
            </>
          ) : (
            <>
              <div className="default-accordion-summary font-medium">
                {summary}
              </div>
              <iconProps.Component
                isOpen={internalValue === DEFAULT_ACCORDION_ID}
              />
            </>
          )}
        </Accordion.Trigger>
        {divideSummary ? (
          <div data-cy="divider" className="w-full border-b px-4" />
        ) : null}

        <Accordion.Content
          data-cy="default-accordion-content"
          className={combineClasses(enablePadding ? 'px-4' : '', 'pb-4')}
        >
          {children}
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  );
};

export default DefaultAccordion;
