import type { MouseEvent, PropsWithChildren } from 'react';
import React, { forwardRef } from 'react';

import { ChipVariant } from './types';
import getVariantClasses from './utils/getVariantClasses';

import combineClasses from '../../utils/combineClasses';
import makeA11YClickEvents from '../../utils/makeA11YClickEvents';
import Button, { ButtonSizes } from '../Button';
import Icon, { Icons } from '../Icon';

export type ChipProps = {
  variant?: ChipVariant;
  onAdd?: VoidFunction;
  onClick?: VoidFunction;
  onDelete?: (e: MouseEvent<HTMLElement>) => void;
  closeDirection?: 'left' | 'right';
};

export { ChipVariant };

const Chip = (
  {
    children,
    variant = ChipVariant.Default,
    onClick,
    onAdd,
    onDelete,
    closeDirection = 'right',
    ...props
  }: PropsWithChildren<ChipProps>,
  ref: React.Ref<HTMLDivElement>,
) => (
  <div
    className={combineClasses(
      'flex w-max items-center gap-1.5 whitespace-nowrap rounded border px-2 py-1/2',
      getVariantClasses(variant),
    )}
    {...(onClick
      ? { ...makeA11YClickEvents(onClick, { stopPropagation: true }) }
      : {})}
    ref={ref}
    {...props}
  >
    {onDelete && closeDirection === 'left' && (
      <button
        onClick={onDelete}
        data-cy="chip-delete-button"
        type="button"
        className="w-max"
      >
        <Icon
          name={variant === ChipVariant.Highlight ? Icons.X__PRIMARY : Icons.X}
        />
      </button>
    )}
    {children}
    {onAdd && (
      <Button
        blank
        onClick={onAdd}
        data-cy="chip-add-button"
        size={ButtonSizes.slim}
      >
        <Icon name={Icons.ADD} />
      </Button>
    )}
    {onDelete && closeDirection === 'right' && (
      <button
        onClick={onDelete}
        data-cy="chip-delete-button"
        type="button"
        className="w-max"
      >
        <Icon
          name={variant === ChipVariant.Highlight ? Icons.X__PRIMARY : Icons.X}
        />
      </button>
    )}
  </div>
);

export default forwardRef<HTMLDivElement, PropsWithChildren<ChipProps>>(Chip);
