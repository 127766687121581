import type { MutableRefObject, ReactElement } from 'react';
import React from 'react';

import DropdownSelectedValues from './DropdownSelectedValues';
import type {
  CustomButtonRender,
  CustomTitleRender,
  DropdownOptionType,
  ToggleDropdown,
} from './types';

import type { ButtonSizes } from '../Button';
import Button from '../Button';
import Text, { TextSize, TextVariant } from '../Text';
import { TextColor } from '../Textv2';

type DropdownEntryButtonProps<ValueType> = {
  id?: string;
  dataCy?: string;
  className?: string;
  filled: boolean;
  border: boolean;
  rounded: boolean;
  toggleDropdown: ToggleDropdown;
  customTitleRender?: CustomTitleRender<ValueType>;
  customButtonRender?: CustomButtonRender<ValueType>;
  customButtonContentRender?: CustomButtonRender<ValueType>;
  customPlaceholderRender?: () => ReactElement;
  selectedOptions: DropdownOptionType<ValueType>[];
  placeholderValue?: string;
  size: ButtonSizes;
  disabled?: boolean;
  entryRef: MutableRefObject<HTMLButtonElement>;
  inlineLabel?: string;
};

function DropdownEntryButton<ValueType>(
  props: DropdownEntryButtonProps<ValueType>,
) {
  const {
    id,
    dataCy,
    className,
    filled,
    border,
    entryRef,
    rounded,
    toggleDropdown,
    customTitleRender,
    customButtonRender,
    customButtonContentRender,
    customPlaceholderRender,
    selectedOptions,
    placeholderValue,
    size,
    disabled,
    inlineLabel,
  } = props;

  if (customButtonRender) {
    return <>{customButtonRender(selectedOptions, toggleDropdown)}</>;
  }

  return (
    <Button
      ref={entryRef}
      id={id}
      data-cy={`dropdown-entry-button-${dataCy || id}`}
      disabled={disabled}
      aria-haspopup="listbox"
      className={`${className} ${
        filled ? 'bg-white' : 'bg-transparent'
      } w-full`}
      onClick={toggleDropdown}
      filled={false}
      border={border}
      rounded={rounded}
      size={size}
    >
      {customButtonContentRender ? (
        customButtonContentRender(selectedOptions, toggleDropdown)
      ) : (
        <div className="flex w-full flex-row items-center justify-between gap-3 pl-1 pr-4">
          {inlineLabel ? (
            <Text
              size={TextSize.s}
              color={TextColor.GRAY}
              variant={TextVariant.body}
            >
              {inlineLabel}
            </Text>
          ) : null}

          <span className="flex-1 truncate text-left">
            <DropdownSelectedValues<ValueType>
              customTitleRender={customTitleRender}
              selectedOptions={selectedOptions}
              placeholderValue={placeholderValue}
              customPlaceholderRender={customPlaceholderRender}
            />
          </span>
        </div>
      )}
    </Button>
  );
}

export default DropdownEntryButton;
