import React from 'react';

// eslint-disable-next-line import/no-cycle
import DialogContent from './DialogContent';
import DialogFooter from './DialogFooter';
import DialogHeader from './DialogHeader';

import Button, { ButtonThemes } from '../Button';
import DeprecatedText, { TextSize, Variant } from '../DeprecatedText';

type ConfirmCloseModalProps = {
  onConfirm: VoidFunction;
  onClose: VoidFunction;
  text?: string;
};

const ConfirmCloseModal = (props: ConfirmCloseModalProps) => {
  const { onClose, onConfirm, text } = props;

  return (
    <>
      <DialogHeader onClose={onClose} id="form-dialog-title">
        Confirm close
      </DialogHeader>
      <DialogContent>
        <DeprecatedText size={TextSize.main} variant={Variant.body}>
          {text}
        </DeprecatedText>
      </DialogContent>
      <DialogFooter>
        <div className="flex gap-3">
          <Button onClick={onClose} filled={false}>
            Nevermind
          </Button>
          <Button
            data-cy="confirm-close"
            theme={ButtonThemes.primary}
            onClick={onConfirm}
          >
            Confirm and Close
          </Button>
        </div>
      </DialogFooter>
    </>
  );
};

export default ConfirmCloseModal;
