import { useState } from 'react';
import { useSelector } from 'react-redux';

import type { Workspace } from '@api/tdm';
import Dialog from '@components/Modals/Dialog';
import Dropdown from '@coral/components/Dropdown';
import DropdownOption from '@coral/components/Dropdown/DropdownOption';
import type { DropdownOptionType } from '@coral/components/Dropdown/types';
import Icon, { Icons } from '@coral/components/Icon';
import { InputSizes } from '@coral/components/InputContainer/types';
import * as selectors from '@global/state/selectors';
import useDialogControl from '@hooks/useDialogControl';
import useSetWorkspaces from '@hooks/useSetWorkspaces';
import useSnorkelRouter from '@hooks/useSnorkelRouter';

import { ROUTES_SUPPORTING_WORKSPACE_SWITCHING } from '../NavigationSidebarFooter';

const kDefaultWorkspaceUid = 1;
const kDefaultWorkspaceLabel = 'Default Workspace';

const calcWorkspaceLabel = (workspace: Workspace | undefined): string => {
  return workspace?.workspace_uid === kDefaultWorkspaceUid
    ? kDefaultWorkspaceLabel
    : (workspace?.name ?? kDefaultWorkspaceLabel);
};

const WorkspaceSelector = () => {
  const router = useSnorkelRouter();

  const workspaces: Workspace[] = useSelector(
    selectors.workspaces.selectWorkspaces,
  );
  const selectedWorkspace: Workspace = useSelector(
    selectors.workspaces.selectSelectedWorkspace,
  );
  const { handleDialogOpen, handleDialogClose, dialogOpen } =
    useDialogControl();
  const { updateSelectedWorkspace } = useSetWorkspaces();

  const [tempSelectedWorkspace, setTempSelectedWorkspace] =
    useState<Workspace | null>(null);

  const handleWorkspaceChange = (option: DropdownOptionType<Workspace>) => {
    const { value: nextWorkspace } = option;

    if (!nextWorkspace) {
      handleDialogOpen();

      return;
    }

    setTempSelectedWorkspace(nextWorkspace);

    const route = router.asPath.split('/').join('');

    if (ROUTES_SUPPORTING_WORKSPACE_SWITCHING.includes(route)) {
      updateSelectedWorkspace(nextWorkspace);
    } else {
      handleDialogOpen();
    }
  };

  const goToHome = () => {
    const index = workspaces.findIndex(
      ({ workspace_uid }) =>
        tempSelectedWorkspace!.workspace_uid === workspace_uid,
    );

    if (index !== -1) {
      updateSelectedWorkspace(workspaces[index]);
      router.push('/');
    }
  };

  return (
    <>
      <Dialog
        title={`Switch workspace${
          tempSelectedWorkspace?.name
            ? ` to ${tempSelectedWorkspace?.name}`
            : ''
        }`}
        message="Switching workspaces will take you to the applications page for that workspace and exit the current application"
        open={dialogOpen}
        handleClose={handleDialogClose}
        actionButtonProps={{
          buttonText: 'Confirm',
          handleConfirm: goToHome,
          buttonDataCy: 'confirm-workspace-switch',
        }}
      />
      <Dropdown<Workspace>
        fullWidth
        searchable
        size={InputSizes.small}
        onSelect={handleWorkspaceChange}
        placeholder="Search for a workspace"
        className="mb-0.5"
        customButtonContentRender={() => (
          <div className="-ml-px flex w-full flex-row gap-x-4 pl-1 pr-3">
            <Icon name={Icons.NOMINAL} />
            <span className="min-w-0 overflow-hidden text-ellipsis">
              {calcWorkspaceLabel(selectedWorkspace)}
            </span>
          </div>
        )}
        value={selectedWorkspace}
        data-cy="sidebarnav-workspace-selector"
      >
        {workspaces.map(workspace => {
          const { workspace_uid } = workspace;

          return (
            <DropdownOption
              key={`workspace-selector-option-${workspace_uid}`}
              value={workspace}
            >
              {calcWorkspaceLabel(workspace)}
            </DropdownOption>
          );
        })}
      </Dropdown>
    </>
  );
};

export default WorkspaceSelector;
