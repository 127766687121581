import tailwindTheme from 'tailwindcss/defaultTheme';

import type {
  SnorkelFontSize,
  SnorkelLineHeight,
} from '@snorkel-themes/themes/types';

const coralLineHeight = {
  small: '0.925rem',
};

const classicLineHeight: SnorkelLineHeight = {
  base: '1.5rem',
  xs: '0.75rem',
  sm: '1.125rem',
  md: '1.3125rem',
  lg: '1.5rem',
  xl: '1.875rem',
  xxl: '2rem',
  xxxl: '3rem',
  smBaseAligned: '1.25rem',
};

export const lineHeight = {
  ...tailwindTheme.lineHeight,
  ...classicLineHeight,
  ...coralLineHeight,
};

const coralFontSize = {
  xxs: ['0.625rem', '1rem'],
  button: ['0.875rem', '1.5rem'],
  xxl: '2rem', // to be deprecated in v2
  canvas: '12px',
  xsmall: ['0.625rem', '12px'],
  small: ['0.75rem', '16px'],
  medium: ['0.875rem', '24px'],
  large: ['1rem', '24px'],
  xlarge: ['1.25rem', '32px'],
  xxlarge: ['1.5rem', { lineHeight: '32px', letterSpacing: '-0.25%' }],
  // From tailwind, but would be overwritten by classicFontSize
  xs: ['0.75rem', '1rem'],
  sm: ['0.875rem', '1.25rem'],
  base: ['1rem', '1.5rem'],
  lg: ['1.125rem', '1.75rem'],
  xl: ['1.25rem', '1.75rem'],
};

const classicFontSize: SnorkelFontSize = {
  base: '1rem',
  xs: '0.625rem',
  sm: '0.75rem',
  md: '0.875rem',
  lg: '1rem',
  xl: '1.25rem',
  xxl: '1.5rem',
  xxxl: '2.25rem',
};

export const fontSize = {
  ...tailwindTheme.fontSize,
  ...classicFontSize,
  ...coralFontSize,
};

export default null;
