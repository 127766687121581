import pipe from '@utils/pipe';

import type { DeploymentState } from './types';

import type { RootState, Selector } from '../../types';

export const selectAll: Selector<DeploymentState> = ({
  deployment,
}: RootState) => deployment;

export const selectName: Selector<string> = pipe(
  selectAll,
  ({ name }: DeploymentState) => name,
);
export const selectDeploymentUid: Selector<number> = pipe(
  selectAll,
  ({ deployment_uid }: DeploymentState) => deployment_uid,
);
export const selectDataSchema = pipe(
  selectAll,
  ({ data_schema }: DeploymentState) => data_schema,
);

export default null;
