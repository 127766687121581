import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Logo from '@components/Logo/logo';
import Icons from '@coral/components/Icon/icons';
import IconButton, { IconButtonSizes } from '@coral/prebuilts/IconButton';
import { DEFAULT_ZOOM } from '@coral/prebuilts/ZoomControl/constants';
import combineClasses from '@coral/utils/combineClasses';
import * as actions from '@global/state/actions';
import * as selectors from '@global/state/selectors';

import NavigationBreadcrumb from './NavigationBreadcrumb';
import NavigationSidebarFooter from './NavigationSidebarFooter';
import NavigationSidebarNav from './NavigationSidebarNav';

// need to push the nav 1px down to align with logo
const HoverableSidebar = () => {
  const dispatch = useDispatch();
  const isExpanded = useSelector(selectors.userPrefs.selectIsSidebarNavVisible);
  const [shouldRevealSidebar, setShouldRevealSidebar] = useState(isExpanded);

  const toggleVisibility = () => {
    dispatch(actions.userPrefs.toggleIsSidebarNavVisible());

    // TODO: ENG-15945 -> Add back tests for zoom
    dispatch(actions.atoms.setPDFZoom(DEFAULT_ZOOM));
  };

  // Common transition classes to keep transitions consistent
  // Delay by 200ms so the toggle button can rotate 180 degrees first
  const transitionClasses = 'transition-all ease-in-out delay-200';

  const headerClasses = combineClasses(
    'py-app absolute top-0 left-0 z-content mb-4 py-5',
    'flex h-toolbar items-center',
    transitionClasses,
    isExpanded ? 'pl-[var(--navSidebarWidth)]' : '',
  );

  const logoClasses = combineClasses(
    'flex justify-center',
    'transition-all delay-200',
    isExpanded ? 'opacity-0 w-0' : 'opacity-100 w-24 ml-10',
  );

  const breadcrumbClasses = combineClasses(
    'pl-app mt-px',
    'transition-all delay-200',
    isExpanded ? '' : '',
  );

  // sidebar container. Show/hide using width
  const asideClasses = combineClasses(
    'sticky flex flex-row justify-start h-full pt-0',
    'text-xs bg-white overflow-visible z-overlay', // z-index needs to be higher than main contents
    'pointer-events-auto',
    transitionClasses,
    isExpanded
      ? 'w-[var(--navSidebarWidth)]' // expand width to show
      : 'w-[var(--navSidebarWidthCollapsed)]', // shrink width to hide
  );

  // Reveal the sidebar on mouse-hover. Used when the side bar is HIDDEN
  const revealClasses = combineClasses(
    'delay-200',
    shouldRevealSidebar
      ? 'translate-x-0' // slide out by translating to zero
      : '-translate-x-[calc(var(--navSidebarWidth)-var(--navSidebarWidthCollapsed))]', // slide in by translating left
  );

  // <nav> element classes - navigation menu container
  // When sidebar is HIDDEN, reveal this on-hover using CSS translate
  const navClasses = combineClasses(
    'relative flex flex-col h-full bg-white',
    'min-w-[var(--navSidebarWidth)] max-w-[var(--navSidebarWidth)]',
    transitionClasses,
    isExpanded ? '' : revealClasses, // nav element needs to translate
  );

  // 1px vertical line between nav and page. Contains the toggle button and
  // provides relative parent for absolute positioning
  // When sidebar is HIDDEN, reveal this on-hover using CSS translate
  const expandBarClasses = combineClasses(
    'relative flex z-overlay',
    'w-px h-full',
    'overflow-visible border-l',
    transitionClasses,
    isExpanded ? '' : revealClasses,
  );

  // Button to expand/hide the nav
  // When sidebar is HIDDEN, reveal this on-hover using CSS translate
  const expandToggleClasses = combineClasses(
    'absolute flex items-center justify-center focus:outline-none',
    'top-[12px] left-[-16px] bg-white',
    'rounded-full border border-gray-200',
    'transition-transform ease-in-out',
    isExpanded ? '' : 'rotate-180',
  );

  // Wraps around main links inside <nav>. When sidebar is HIDDEN, 20px is
  // still visible for catching mouse-over. So hide these using CSS opacity
  const navLinks = combineClasses(
    'flex flex-1 flex-col px-2',
    transitionClasses,
    shouldRevealSidebar ? 'opacity-1' : 'opacity-0',
  );

  // When NOT expanded, handle mouseOver on the <nav> element only.
  // This keeps the nav from sliding out when hovering over the toggle button.
  const handleNavMouseOver = () => {
    setShouldRevealSidebar(true);
  };

  // When NOT expanded, mouseLeave is handled in the parent <aside> element, so
  // we capture the mouse leaving the whole nav, including the toggle button.
  const handleAsideMouseLeave = () => {
    if (isExpanded) {
      return;
    }

    setShouldRevealSidebar(false);
  };

  const handleToggleVisibilityClicked = () => {
    toggleVisibility();

    // NOTE: do not simply flip the shouldRevealSidebar value here. isExpanded
    // aka "Visibility" is an app-level state value used to collapse/expand the
    // sidebar, stored in redux. "Reveal" is local state here, mainly for
    // CSS during mouseover/mouseleave. When we expand/collapse, we want the
    // "reveal" value to match at that moment.
    setShouldRevealSidebar(!isExpanded);
  };

  return (
    <>
      <div className={headerClasses}>
        <div className={logoClasses} key="hoverable-sidebar-header-logo">
          <Logo theme="dark" svg isHidden={isExpanded} />
        </div>
        <div className={breadcrumbClasses}>
          <NavigationBreadcrumb />
        </div>
      </div>
      <aside
        className={asideClasses}
        data-cy="hoverable-sidebar-navigation"
        onMouseLeave={handleAsideMouseLeave}
        onBlur={handleAsideMouseLeave}
      >
        <nav
          className={navClasses}
          onMouseOver={handleNavMouseOver}
          onFocus={handleNavMouseOver}
          key="hoverable-sidebar-nav"
          aria-hidden={!isExpanded && !shouldRevealSidebar}
        >
          <div
            className="flex h-toolbar min-h-toolbar w-full items-center justify-between px-4"
            key="hoverable-sidebar-logo"
          >
            <Logo theme="dark" svg />
          </div>
          <div className={navLinks}>
            <NavigationSidebarNav />
            <NavigationSidebarFooter />
          </div>
        </nav>
        <div className={expandBarClasses}>
          <IconButton
            icon={Icons.COLLAPSE__LEFT}
            onClick={handleToggleVisibilityClicked}
            data-cy={
              isExpanded
                ? 'collapse-left-sidebar-button'
                : 'expand-left-sidebar-button'
            }
            className={expandToggleClasses}
            tooltip={isExpanded ? 'Hide sidebar' : 'Show sidebar'}
            size={IconButtonSizes.small}
            active
          />
        </div>
      </aside>
    </>
  );
};

export default HoverableSidebar;
