import { debounce } from 'lodash';
import type { RefObject } from 'react';
import { useEffect, useState } from 'react';

const kMillisDebounce = 350;

const useElementWidth = (refElement: RefObject<HTMLElement>): number => {
  const [elementWidth, setElementWidth] = useState(0);

  useEffect(() => {
    if (refElement.current) {
      setElementWidth(refElement.current.offsetWidth);
    }

    const handleResize = debounce(() => {
      if (refElement.current) {
        setElementWidth(refElement.current.offsetWidth);
      }
    }, kMillisDebounce);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [refElement]);

  return elementWidth;
};

export default useElementWidth;
