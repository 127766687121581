import type { MouseEventHandler, PropsWithChildren } from 'react';

import { TextColorPrimitive } from '@coral/__internal__/primitives/types';
import Text, {
  TextSize,
  TextVariant,
  TextWeight,
} from '@coral/components/Text';
import StackPrimitive from '@coral/layout/__internal__/StackPrimative';
import IconButton from '@coral/prebuilts/IconButton';

import combineClasses from '../../../utils/combineClasses';
import { Icons } from '../../Icon';
import type { ExpandableAccordionProps } from '../types';

type AccordionControlProps = PropsWithChildren<{}>;

const AccordionControl = ({ children }: AccordionControlProps) => (
  <StackPrimitive className="box-border flex h-toolbar flex-row items-center justify-between border-b bg-coolGray-100 p-3">
    {children}
  </StackPrimitive>
);

const ExpandableAccordion = ({
  summary,
  children,
  control,
  onClick,
  subtext,
  mode,
  setMode,
  className,
  isExpandEnabled,
  ...props
}: ExpandableAccordionProps) => {
  const onAccordionClick: MouseEventHandler<HTMLDetailsElement> = e => {
    if (onClick) {
      if (e && e.preventDefault) {
        e.preventDefault();
      }

      onClick();
    }
  };

  const isOpenOrExpanded = mode === 'open' || mode === 'expanded';

  return (
    <div className="overflow-hidden rounded-lg border">
      <details open={isOpenOrExpanded} {...props}>
        <summary
          onClick={onAccordionClick}
          className={combineClasses(
            'flex cursor-pointer items-center justify-between p-3 focus:outline-none',
            isOpenOrExpanded ? 'bg-coolGray-200' : 'bg-coolGray-100',
            className,
          )}
          data-cy={`${props['data-cy']}-summary`}
        >
          <div className="mx-2 flex w-full flex-row items-center justify-between gap-4">
            <Text
              variant={TextVariant.itemTitle}
              weight={TextWeight.semibold}
              size={TextSize.md}
            >
              {summary}
            </Text>
            {isOpenOrExpanded ? (
              <div
                className="flex flex-row"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <IconButton
                  icon={Icons.SLASH__HORIZONTAL}
                  onClick={() => {
                    setMode?.('closed');
                  }}
                />

                {isExpandEnabled ? (
                  <IconButton
                    icon={mode === 'expanded' ? Icons.MINIMIZE : Icons.MAXIMIZE}
                    onClick={() => {
                      if (mode === 'expanded') {
                        setMode?.('open');

                        return;
                      }

                      setMode?.('expanded');
                    }}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
          <div>
            {subtext && !isOpenOrExpanded ? (
              <Text size={TextSize.s} color={TextColorPrimitive.DEFAULT}>
                {subtext}
              </Text>
            ) : null}
          </div>
        </summary>
        {control ? <AccordionControl>{control}</AccordionControl> : null}
        <div className="box-border">{children}</div>
      </details>
    </div>
  );
};

export default ExpandableAccordion;
