import { colors } from './colors';
import { borderRadius, gap, margin, padding } from './layout';
import { fontSize, lineHeight } from './typography';

import {
  animation,
  borderWidth,
  boxShadow,
  fontFamily,
  height,
  inset,
  keyframes,
  letterSpacing,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  opacity,
  screens,
  spacing,
  width,
  zIndex,
} from '../common';

export const theme = {
  colors,
  fontSize,
  lineHeight,
  letterSpacing,
  keyframes,
  animation,
  opacity,
  zIndex,
  screens,
  borderWidth,
  borderRadius,
  spacing,
  padding,
  width,
  height,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  inset,
  boxShadow,
  fontFamily,
  margin,
  gap,
};

export default null;
