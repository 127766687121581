import { isNil } from 'lodash';

const kCharToPixelMultiplier = 10;
const kDropdownPadding = 28;
const kDropdownMinWidth = 100;

export type DropdownWidthVariant = 'default' | 'wide';

const mapDropdownWidthToWidthInPixel: Record<DropdownWidthVariant, number> = {
  default: 300,
  wide: 400,
};

const calcDropdownWidth = (
  widthVariant: DropdownWidthVariant,
  hardcodedDropdownWidth: number | undefined,
  containerWidth: number,
  labels: string[],
  placeholder: string | undefined,
) => {
  if (!isNil(hardcodedDropdownWidth)) return hardcodedDropdownWidth;

  let longestLabel = labels.reduce(
    (max, { length }) => (length > max ? length : max),
    0,
  );

  // Check if placeholder is larger than the longest label
  if (placeholder && placeholder.length > longestLabel) {
    longestLabel = placeholder.length;
  }

  const idealWidth = longestLabel * kCharToPixelMultiplier + kDropdownPadding;

  // Make sure that the dropdown is not wider than the max width or smaller than the min width
  const maxNextWidth = Math.min(
    idealWidth,
    mapDropdownWidthToWidthInPixel[widthVariant],
  );
  const minNextWidth = Math.max(maxNextWidth, kDropdownMinWidth);

  return Math.max(containerWidth, minNextWidth);
};

export default calcDropdownWidth;
