import { addSeconds } from 'date-fns';

import { usersApi } from '@utils/api/serverRequests';

import iso8601ToSeconds from './iso8601ToSeconds';
import { getUserSupportedFeatures } from './supportedFeatures';

const getAccessToken = async (refreshToken?: string) => {
  if (!refreshToken) {
    throw new Error('No refresh token');
  }

  const {
    status,
    data: { access_token, expires_in, refresh_token: newRefreshToken },
  } = await usersApi.refreshTokenRefreshTokenPost({
    refreshToken: { refresh_token: refreshToken },
  });

  if (!access_token || status >= 400) {
    throw new Error('Login failed');
  }

  return {
    accessToken: access_token,
    refreshToken: newRefreshToken,
    // TODO(ENG-31749): handle this parsing in BE and remove the parsing logic from FE
    expiry: addSeconds(new Date(), iso8601ToSeconds(expires_in)).getTime(),
    supportedFeatures: getUserSupportedFeatures(),
  };
};

export default getAccessToken;
