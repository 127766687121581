import NotebookModalButton from '@components/NotebookModalButton';
import { Icons } from '@coral/components/Icon';
import NavigationButton from '@global/NavigationContainer/common/NavigationButton';
import useCachedIsTrial from '@hooks/useCachedIsTrial';

const NotebookItem = () => {
  const isTrial = useCachedIsTrial();

  return (
    <NotebookModalButton
      button={({ onClick }: any) => (
        <NavigationButton
          onClick={onClick}
          disabled={isTrial}
          leftIcon={Icons.NOTEBOOK}
          selected={false}
        >
          <span className="flex-1 truncate py-1">Notebook</span>
        </NavigationButton>
      )}
    />
  );
};

export default NotebookItem;
