import isEqual from 'lodash/isEqual';

import type { DataConnector } from '@api/tdm/api';
import { DataConnector as DataConnectorObject } from '@api/tdm/api';
import { StudioViewModes } from '@app/StudioPage/types';
import type { LFFilterState } from '@coral/types';
import { LabelSortType } from '@coral/types';
import { DEFAULT_SELECTED_LF } from '@core/constants';
import { emptyList, emptyObj } from '@core/empty';
import type { RunningJob } from '@core/types';
import { SplitType } from '@core/types';
import { createReducerSlice } from '@utils/redux/reducer';
import { DEFAULT_ZOOM } from 'src/coral/prebuilts/ZoomControl/constants';

import type { AtomsState } from './types';

export const defaultLFFilters: LFFilterState = {
  pattern: '',
  name: '',
  authors: emptyList,
  labels: emptyList,
};

export const defaultDataConnectorConfigList: AtomsState['dataConnectorConfigList'] =
  Object.values(DataConnectorObject).reduce(
    (acc: AtomsState['dataConnectorConfigList'], connector: DataConnector) => {
      acc[connector] = null;

      return acc;
    },
    {} as AtomsState['dataConnectorConfigList'],
  );

export const initialState: AtomsState = {
  modelJobId: '',

  selectedLfId: DEFAULT_SELECTED_LF,
  lfsVotedInfoResponse: null,
  notifications: emptyList,
  suggestField: null,
  studioViewMode: StudioViewModes.RECORD_VIEW,
  pdfData: undefined,
  isPDFDataLoading: false,
  pdfZoom: DEFAULT_ZOOM,
  selectedLabelSortType: LabelSortType.ALPHANUMERIC,

  lfTableFilters: defaultLFFilters,
  suggestedLFTableFilters: defaultLFFilters,

  userChannelSocket: null,

  dataConnectorConfigList: defaultDataConnectorConfigList,

  analysisSplit: SplitType.VALID,
  deleteDatasourceJobs: emptyObj,

  datasetParams: {},
};

const reducerSlice = createReducerSlice('atoms', initialState, {
  updateModelJobId(state: AtomsState, modelJobId: string): AtomsState {
    return modelJobId === state.modelJobId ? state : { ...state, modelJobId };
  },

  setSelectedLfId(
    state: AtomsState,
    selectedLfId: AtomsState['selectedLfId'],
  ): AtomsState {
    return selectedLfId === state.selectedLfId
      ? state
      : { ...state, selectedLfId };
  },

  setLfsVotedInfoResponse(
    state: AtomsState,
    lfsVotedInfoResponse: AtomsState['lfsVotedInfoResponse'],
  ): AtomsState {
    return lfsVotedInfoResponse === state.lfsVotedInfoResponse ||
      isEqual(lfsVotedInfoResponse, state.lfsVotedInfoResponse)
      ? state
      : { ...state, lfsVotedInfoResponse };
  },

  setNotifications(
    state: AtomsState,
    notifications: AtomsState['notifications'],
  ): AtomsState {
    return notifications === state.notifications ||
      (notifications.length === state.notifications.length &&
        isEqual(notifications, state.notifications))
      ? state
      : { ...state, notifications };
  },

  setSuggestField(
    state: AtomsState,
    suggestField: AtomsState['suggestField'],
  ): AtomsState {
    return suggestField === state.suggestField
      ? state
      : { ...state, suggestField };
  },

  setStudioViewMode(
    state: AtomsState,
    studioViewMode: AtomsState['studioViewMode'],
  ): AtomsState {
    return studioViewMode === state.studioViewMode
      ? state
      : { ...state, studioViewMode };
  },

  setPDFDataLoading(
    state: AtomsState,
    isPDFDataLoading: AtomsState['isPDFDataLoading'],
  ): AtomsState {
    return isPDFDataLoading === state.isPDFDataLoading
      ? state
      : { ...state, isPDFDataLoading };
  },

  setPDFData(state: AtomsState, pdfData: AtomsState['pdfData']): AtomsState {
    return isEqual(pdfData, state.pdfData) ? state : { ...state, pdfData };
  },

  setPDFZoom(state: AtomsState, pdfZoom: AtomsState['pdfZoom']): AtomsState {
    return pdfZoom === state.pdfZoom ? state : { ...state, pdfZoom };
  },

  setSelectedLabelSortType(
    state: AtomsState,
    selectedLabelSortType: AtomsState['selectedLabelSortType'],
  ): AtomsState {
    return selectedLabelSortType === state.selectedLabelSortType
      ? state
      : { ...state, selectedLabelSortType };
  },

  setLFTableFilters(
    state: AtomsState,
    lfTableFilters: LFFilterState,
  ): AtomsState {
    return isEqual(lfTableFilters, state.lfTableFilters)
      ? state
      : { ...state, lfTableFilters };
  },

  resetLFTableFilters(state: AtomsState): AtomsState {
    return state.lfTableFilters === defaultLFFilters
      ? state
      : { ...state, lfTableFilters: defaultLFFilters };
  },

  setSuggestedLFTableFilters(
    state: AtomsState,
    suggestedLFTableFilters: LFFilterState,
  ): AtomsState {
    return isEqual(suggestedLFTableFilters, state.suggestedLFTableFilters)
      ? state
      : { ...state, suggestedLFTableFilters };
  },

  setUserChannelSocket(
    state: AtomsState,
    userChannelSocket: AtomsState['userChannelSocket'],
  ): AtomsState {
    return userChannelSocket === state.userChannelSocket
      ? state
      : { ...state, userChannelSocket };
  },

  setDataConnectorConfigList(
    state: AtomsState,
    dataConnectorConfigList: AtomsState['dataConnectorConfigList'],
  ): AtomsState {
    return isEqual(dataConnectorConfigList, state.dataConnectorConfigList)
      ? state
      : { ...state, dataConnectorConfigList };
  },

  setAnalysisSplit(
    state: AtomsState,
    analysisSplit: AtomsState['analysisSplit'],
  ): AtomsState {
    return analysisSplit === state.analysisSplit
      ? state
      : { ...state, analysisSplit };
  },

  resetDeleteDatasourceJobs(state: AtomsState): AtomsState {
    return state.deleteDatasourceJobs === emptyObj
      ? state
      : { ...state, deleteDatasourceJobs: emptyObj };
  },

  addDeleteDatasourceJob(state: AtomsState, job: RunningJob): AtomsState {
    let { deleteDatasourceJobs } = state;
    deleteDatasourceJobs = { ...deleteDatasourceJobs, [job.uid]: job };
    state = { ...state, deleteDatasourceJobs };

    return state;
  },

  addDeleteDatasourceJobs(state: AtomsState, jobs: RunningJob[]): AtomsState {
    const deleteDatasourceJobs: Record<string, RunningJob> =
      jobs.length === 0
        ? emptyObj
        : jobs.reduce((acc: Record<string, RunningJob>, job: RunningJob) => {
            acc[job.uid] = job;

            return acc;
          }, {});
    state = { ...state, deleteDatasourceJobs };

    return state;
  },

  removeDeleteDatasourceJob(state: AtomsState, jobId: string): AtomsState {
    const { deleteDatasourceJobs } = state;
    const { [jobId]: jobToRemove, ...remainingJobs } = deleteDatasourceJobs;

    return jobToRemove === undefined
      ? state
      : { ...state, deleteDatasourceJobs: remainingJobs };
  },

  setDatasetParams(
    state: AtomsState,
    datasetParams: AtomsState['datasetParams'],
  ): AtomsState {
    return datasetParams === state.datasetParams
      ? state
      : { ...state, datasetParams };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;
