import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as selectors from '@global/state/selectors';
import useRequestWithLogging from '@hooks/useRequestWithLogging';
import useSnorkelRouter from '@hooks/useSnorkelRouter';
import { resourceRecencyApi } from '@utils/api/serverRequests';

const withRecencyUpdater = Component => props => {
  const { asPath } = useSnorkelRouter();
  const request = useRequestWithLogging();
  const userUid = useSelector(selectors.auth.selectUserUid);

  useEffect(() => {
    // Dataset Pages
    const datasetUid = asPath?.match(/\/datasets\/([0-9]+)/)?.[1];

    if (datasetUid) {
      request(
        resourceRecencyApi.viewDatasetUsersUserUidViewDatasetDatasetUidPost,
        {
          datasetUid: Number(datasetUid),
          userUid,
        },
      );
    }

    // Application Pages
    const applicationUid = asPath?.match(/\/applications\/([0-9]+)/)?.[1];

    if (applicationUid) {
      request(
        resourceRecencyApi.viewApplicationUsersUserUidViewApplicationApplicationUidPost,
        {
          userUid,
          applicationUid: Number(applicationUid),
        },
      );
    }
  }, [asPath, request, userUid]);

  return <Component {...props} />;
};

export default withRecencyUpdater;
