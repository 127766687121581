import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import * as selectors from '@global/state/selectors';
import useCachedIsTrial from '@hooks/useCachedIsTrial';
import useCachedRequest from '@hooks/useCachedRequest';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import { notebooksApi } from '@utils/api/serverRequests';
import publicRuntimeConfig from '@utils/publicRuntimeConfig';

const useNotebookState = () => {
  const isTrial = useCachedIsTrial();
  const isLoggingOut = useSelector(selectors.auth.selectIsLoggingOut);
  const [open, setOpen] = useState<boolean>(false);
  const [url, setUrl] = useState<string | undefined>();
  const [key, setKey] = useState<number>(0);
  const { handleCopy } = useCopyToClipboard();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const basePath = publicRuntimeConfig.NEXT_PUBLIC_BASE_PATH ?? '';

  const { data: notebookPath, isLoading: isNotebookPathLoading } =
    useCachedRequest(
      notebooksApi.getSingleruserSelfNotebookSingleuserNotebookSelfGet,
      {},
      { suspendFetch: isTrial || isLoggingOut },
    );

  const { data: notebookDirPath, isLoading: isNotebookDirPathLoading } =
    useCachedRequest(
      notebooksApi.getSingleuserSelfNotebookDirSingleuserNotebookSelfDirGet,
      {},
      { suspendFetch: isTrial || isLoggingOut },
    );

  const copyNotebookUrl = () => {
    if (url) {
      handleCopy(url, 'Notebook URL');
    }
  };

  const handleFileBrowserClick = () => {
    if (!isNotebookDirPathLoading && notebookDirPath) {
      const newUrl = `${window.location.origin}${basePath}${notebookDirPath.path}`;
      setUrl(newUrl);
      // Force IFrame to Update
      setKey(key + 1);
    }
  };

  useEffect(() => {
    if (!isNotebookPathLoading && notebookPath) {
      const newUrl = `${window.location.origin}${basePath}${notebookPath.path}`;
      setUrl(newUrl);
    }
  }, [basePath, isNotebookPathLoading, notebookPath]);

  const isDisabled = isLoggingOut || isTrial;
  const isLoading = isNotebookPathLoading || isNotebookDirPathLoading;

  return {
    open,
    url,
    key,
    handleOpen,
    handleClose,
    handleFileBrowserClick,
    copyNotebookUrl,
    isLoading,
    isDisabled,
  };
};

export default useNotebookState;
