import React from 'react';

import { normalizeUrl } from '@utils/normalizeUrl';

type LogoProps = {
  theme?: 'dark' | 'light';
  svg?: boolean;
  isHidden?: boolean;
};

const Logo = ({ theme = 'light', svg, isHidden }: LogoProps) => {
  const imageUrl =
    theme === 'light' ? '/static/logo.ico' : `/static/logo_header.svg`;

  const calcSize = () => {
    if (isHidden) return 0;

    return svg ? '96px' : '110px';
  };

  const size = { width: calcSize() };

  return (
    <div className="flex flex-none items-center">
      <a href={normalizeUrl('/')}>
        <img
          className="transition-all delay-200"
          src={normalizeUrl(imageUrl)}
          style={size}
          alt="Snorkel Logo"
        />
      </a>
    </div>
  );
};

export default Logo;
