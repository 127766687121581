import useGetLicense from '@app/AdminPage/hooks/useGetLicense';
import Alert, { AlertTheme } from '@coral/components/Alert';
import { differenceInDays } from '@coral/utils/date';

const LicenseWarnings = () => {
  const { accountInfo, expirationBuffers } = useGetLicense();
  const expirationDelta = accountInfo?.expiration_date
    ? differenceInDays(accountInfo?.expiration_date, new Date())
    : 0;

  const showLicenseWillExpireWarning =
    expirationBuffers &&
    expirationDelta > 0 &&
    expirationDelta <= expirationBuffers?.license_until_expiration_days;
  const showLicenseExpiredError = expirationBuffers && expirationDelta <= 0;

  if (!showLicenseWillExpireWarning && !showLicenseExpiredError) {
    return null;
  }

  return (
    <div className="mb-2 px-2">
      {showLicenseWillExpireWarning && (
        <Alert theme={AlertTheme.warning} data-cy="license-warning">
          Your Snorkel Flow license will expire in {expirationDelta} days!
          Contact the Snorkel AI team to update your license.
        </Alert>
      )}
      {showLicenseExpiredError && (
        <Alert theme={AlertTheme.error} data-cy="license-expired">
          Your Snorkel Flow license has expired. There are{' '}
          {expirationBuffers.license_buffer_days + expirationDelta} days
          remaining before this instance becomes inaccessible.
        </Alert>
      )}
    </div>
  );
};

export default LicenseWarnings;
