/* eslint-disable no-nested-ternary */
import type {
  ChangeEvent,
  MutableRefObject,
  ReactElement,
  ReactNode,
  Ref,
} from 'react';
import { useEffect, useId, useImperativeHandle, useMemo, useRef } from 'react';

import { combineTwClasses } from '@coral/utils/combineClasses';

import DropdownEntryButton from './DropdownEntryButton';
import DropdownEntryInput from './DropdownEntryInput';
import DropdownList from './DropdownList';
import useDropdownFilter from './hooks/useDropdownFilter';
import useDropdownKeyboard from './hooks/useDropdownKeyboard';
import useDropdownValue from './hooks/useDropdownValue';
import useDropdownVisibility from './hooks/useDropdownVisibility';
import useElementWidth from './hooks/useElementWidth';
import type {
  CustomButtonRender,
  CustomTitleRender,
  DropdownSearchableProps,
  OnChangeSearchValue,
  OnSelect,
} from './types';
import type { DropdownWidthVariant } from './utils/calcDropdownWidth';
import calcDropdownWidth from './utils/calcDropdownWidth';
import getOptions from './utils/getOptions';

import Overlay, { DEFAULT_OFFSET } from '../../layout/Overlay';
import { OverlayPlacement } from '../../layout/Overlay/types';
import { ButtonSizes } from '../Button';
import Icon, { Icons } from '../Icon';
import type { InputContainerDetailsProps } from '../InputContainer';
import InputContainer from '../InputContainer';
import { InputSizes } from '../InputContainer/types';
import Spinner from '../Spinner';

export interface DropdownProps<T = string>
  extends Omit<InputContainerDetailsProps, 'size'> {
  combobox?: boolean;
  children: ReactNode;
  value?: any;
  multiSelect?: boolean;
  customTitleRender?: CustomTitleRender<T>;
  customOptionRender?: CustomTitleRender<T>;
  customButtonRender?: CustomButtonRender<T>;
  customPlaceholderRender?: () => ReactElement;
  customButtonContentRender?: CustomButtonRender<T>;
  defaultValue?: any;
  border?: boolean;
  rounded?: boolean;
  filled?: boolean;
  caret?: boolean;
  placeholder?: string;
  className?: string;
  clearable?: boolean;
  onSelect?: OnSelect<T>;
  overrideSelect?: OnSelect<T>;
  'data-cy'?: string;
  maxHeightClass?: string;
  dropdownWidth?: number;
  dropdownRef?: Ref<DropdownRefProps>;
  required?: boolean;
  emptyMessage?: string;
  searchable?: DropdownSearchableProps;
  size?: InputSizes;
  autoFocus?: boolean;
  disableUnselectedOptions?: boolean;
  isLoading?: boolean;
  inlineLabel?: string;
  widthVariant?: DropdownWidthVariant;
}

export type DropdownRefProps = {
  openDropdown: VoidFunction;
  closeDropdown: VoidFunction;
};

const MAX_HEIGHT_DEFAULT_CLASS = 'max-h-96';

export type DropdownOnSelect = DropdownProps['onSelect'];

function Dropdown<ValueType = string>(props: DropdownProps<ValueType>) {
  const {
    combobox,
    value: passedValue,
    defaultValue,
    placeholder,
    onSelect,
    dropdownRef,
    customButtonRender,
    customButtonContentRender,
    customTitleRender,
    customOptionRender,
    customPlaceholderRender,
    border = true,
    rounded = true,
    filled = true,
    caret = true,
    required,
    'data-cy': dataCy,
    className,
    size = InputSizes.large,
    label,
    infoText,
    error,
    disabled,
    children,
    overrideSelect,
    inlineLabel,
    // determines how many dropdown items are generated that are not hidden by scroll
    maxHeightClass,
    dropdownWidth: hardcodedDropdownWidth,
    clearable = false,
    multiSelect = false,
    emptyMessage,
    tooltipTitle,
    searchable: searchableProps,
    autoFocus,
    disableUnselectedOptions,
    isLoading = false,
    widthVariant = 'default',
  } = props;

  const fallbackId = useId();

  // assign persistent id:
  let { id } = props;
  const refId = useRef(id ?? `dropdown-${fallbackId}`);
  id = refId.current;

  const searchable = combobox ? false : searchableProps || false;

  const { options, groups } = getOptions<ValueType>(children);

  const entryRef = useRef<HTMLInputElement | HTMLButtonElement>();
  const searchInputRef = useRef<HTMLInputElement>();

  const focusEntry = () => {
    if (entryRef.current) {
      entryRef.current.focus();
    }
  };

  const {
    activeValueIndex,
    allSelectedValues,
    allSelectedOptions,
    fallbackIndex,
    searchValue,
    currentTitle,
    onSelectItem,
    setSearchValue,
    setValuesByIndex,
    setActiveValueIndex,
    clearSelection,
  } = useDropdownValue<ValueType>({
    options,
    passedValue,
    defaultValue,
    multiSelect,
    focusEntry,
    searchable: !!searchable,
    overrideSelect,
    onSelect,
    combobox,
  });

  const {
    filteredOptions,
    setShouldFilter,
    selectedGroup,
    setSelectedGroup,
    traverseGroup,
  } = useDropdownFilter({
    activeValueIndex,
    setActiveValueIndex,
    searchable,
    fallbackIndex,
    groups,
    searchValue,
    options,
    combobox,
  });

  const {
    toggleDropdown,
    openDropdown,
    closeDropdown,
    isDropdownVisible,
    ref,
    childRef,
  } = useDropdownVisibility<ValueType>({
    setShouldFilter,
    disabled,
    searchValue,
    currentTitle,
    options,
    setSearchValue,
    combobox,
    searchable: !!searchable,
    setValuesByIndex,
    fallbackIndex,
    multiSelect,
    autoFocus,
  });
  const containerWidth = useElementWidth(ref);

  const { keyHandler } = useDropdownKeyboard<ValueType>({
    disabled,
    closeDropdown,
    focusEntry,
    openDropdown,
    activeValueIndex,
    setActiveValueIndex,
    filteredOptions,
    options,
    setValuesByIndex,
    isDropdownVisible,
    onSelectItem,
    multiSelect,
    traverseGroup,
    id,
  });

  useImperativeHandle(dropdownRef, () => ({
    openDropdown,
    closeDropdown,
  }));

  const onChangeSearchValue: OnChangeSearchValue = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(value);
    setShouldFilter(true);
    openDropdown();
  };

  const placeholderValue = placeholder || 'Select a value';

  // Calculate the width of the widest option unless a hardcoded width exists
  const dropdownWidth = useMemo(
    () =>
      calcDropdownWidth(
        widthVariant,
        hardcodedDropdownWidth,
        containerWidth,
        options.map(({ title }) => title),
        placeholder,
      ),
    [
      widthVariant,
      containerWidth,
      hardcodedDropdownWidth,
      options,
      placeholder,
    ],
  );

  useEffect(() => {
    // auto-focus on search input for searchable dropdown
    if (isDropdownVisible && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isDropdownVisible]);

  return (
    <div
      onKeyDown={keyHandler}
      ref={ref}
      role="listbox"
      tabIndex={-1}
      className={`relative w-full ${className}`}
      {...(dataCy ? { 'data-cy': dataCy } : {})}
    >
      <Overlay
        open={isDropdownVisible}
        offset={DEFAULT_OFFSET}
        static
        placement={OverlayPlacement.BOTTOM_START}
        content={
          <div className={isDropdownVisible ? '' : 'none'}>
            <DropdownList
              id={id}
              searchable={searchable}
              searchValue={searchValue}
              onChangeSearchValue={onChangeSearchValue}
              dataCy={dataCy}
              groups={groups}
              emptyMessage={emptyMessage}
              width={dropdownWidth}
              containerRef={ref}
              activeValueIndex={activeValueIndex}
              filteredOptions={filteredOptions}
              childRef={childRef}
              entryRef={searchInputRef as Ref<HTMLInputElement>}
              multiSelect={multiSelect}
              onSelectItem={item => {
                onSelectItem(item);

                if (!multiSelect) {
                  closeDropdown(true);
                }
              }}
              allSelectedValues={allSelectedValues}
              customTitleRender={customOptionRender || customTitleRender}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
              disableUnselectedOptions={disableUnselectedOptions}
              maxHeightClass={maxHeightClass || MAX_HEIGHT_DEFAULT_CLASS}
              size={size}
            />
          </div>
        }
      >
        <InputContainer
          size={size}
          fullWidth
          label={label}
          infoText={infoText}
          error={error}
          id={id}
          disabled={disabled}
          tooltipTitle={tooltipTitle}
        >
          <div className="relative h-full w-full">
            {combobox ? (
              <DropdownEntryInput<ValueType>
                disabled={disabled}
                required={required}
                entryRef={entryRef as Ref<HTMLInputElement>}
                searchValue={searchValue}
                onChangeSearchValue={onChangeSearchValue}
                toggleDropdown={toggleDropdown}
                selectedOptions={allSelectedOptions}
                placeholderValue={placeholderValue}
                className={combineTwClasses(className, { 'pr-11': clearable })}
                multiSelect={multiSelect}
                id={id}
                size={size}
                dataCy={dataCy}
                customButtonContentRender={customButtonContentRender}
              />
            ) : (
              <DropdownEntryButton<ValueType>
                filled={filled}
                border={border}
                entryRef={entryRef as MutableRefObject<HTMLButtonElement>}
                rounded={rounded}
                toggleDropdown={toggleDropdown}
                selectedOptions={allSelectedOptions}
                customTitleRender={customTitleRender}
                customButtonRender={customButtonRender}
                customButtonContentRender={customButtonContentRender}
                placeholderValue={placeholderValue}
                className={className}
                size={ButtonSizes[size]}
                disabled={disabled}
                id={id}
                dataCy={dataCy}
                inlineLabel={inlineLabel}
                customPlaceholderRender={customPlaceholderRender}
              />
            )}
            {clearable && currentTitle ? (
              <div className="absolute right-0 top-0 mr-6 flex h-full items-center opacity-80">
                <button
                  type="button"
                  data-cy="clearable-button"
                  onKeyDown={e => e.stopPropagation()}
                  onClick={clearSelection}
                  className="z-content flex h-5 w-5 items-center justify-center rounded-full bg-white"
                >
                  <Icon name={Icons.X} />
                </button>
              </div>
            ) : null}
            {isLoading ? (
              <div
                className="absolute right-0 top-0 mr-3 flex h-full items-center"
                data-cy="dropdown-spinner"
              >
                <Spinner />
              </div>
            ) : caret ? (
              <div
                className="pointer-events-none absolute right-0 top-0 mr-2 flex h-full items-center"
                data-cy="dropdown-caret"
              >
                <Icon name={Icons.CARET__DOWN} />
              </div>
            ) : null}
          </div>
        </InputContainer>
      </Overlay>
    </div>
  );
}

export default Dropdown;
