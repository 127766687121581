import { ChipVariant } from '../types';

const getVariantClasses = (variant: ChipVariant) => {
  switch (variant) {
    case ChipVariant.Dashed:
      return 'border-black border-dashed hover:bg-gray-200';
    case ChipVariant.Highlight:
      return 'border-blue-500 bg-blue-100 hover:bg-blue-200';
    case ChipVariant.Disabled:
      return 'border-gray-700 bg-gray-100 text-gray-700 hover:none';
    default:
      return 'hover:bg-gray-200';
  }
};

export default getVariantClasses;
