import { addMinutes, format, isSameDay, parseISO } from 'date-fns';

export {
  addHours,
  subHours,
  addDays,
  subDays,
  addMilliseconds,
  subMilliseconds,
  isWithinInterval,
  formatDistanceToNow,
  isFuture,
  isAfter,
  compareAsc,
  differenceInDays,
  differenceInSeconds,
  format,
} from 'date-fns';

export enum DateFormat {
  default = 'MM-dd-yyyy',
  slashSeparated = 'MM/dd/yyyy',
  monthYear = 'MMMM yyyy',
  monthDay = 'MMM d',
  hourAmPm = 'ha',
  longDate = 'MMMM d, yyyy',
  longDateTime = 'MMM d, yyyy h:mm a',
  dateWithFullHour = 'MM/dd/yy H:00',
  fullDateTime = 'yyyy-MM-dd HH:mm:ss',
  fullDateTimeAmPm = 'h:mm a MM/dd/yyyy',
  time = 'HH:mm:ss',
  timeAmPm = 'h:mm a',
}

export function utcToLocalDate(utcDate: string) {
  const date = parseISO(utcDate);

  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
}

export function formatDate(
  date: Date | string | number,
  dateFormat = DateFormat.default,
) {
  return format(date || new Date(), dateFormat);
}

export function formatElapsedTime(elapsedTimeInSeconds: number) {
  const date = new Date(elapsedTimeInSeconds * 1000);

  return format(addMinutes(date, date.getTimezoneOffset()), DateFormat.time);
}

export function formatRelativeToToday(date: Date | string | number) {
  if (isSameDay(date, new Date())) {
    return formatDate(date, DateFormat.timeAmPm);
  }

  return formatDate(date, DateFormat.default);
}

export default null;
