import { StudioViewModes } from '@app/StudioPage/types';

// Options of number of datapoints to display in paginated data views.
export const GROUPED_VIEW_PAGE_SIZES = [20, 50, 100, 200];
export const GROUPED_VIEW_DEFAULT_PAGE_SIZE = 20;

// Number of LFs to display per page.
// We can eventually make this configurable in the UI.
export const LF_LIST_PAGE_COUNT = 10;

export const TextViewModes = [
  StudioViewModes.RECORD_VIEW,
  StudioViewModes.SEARCH_VIEW,
  StudioViewModes.RAW,
  StudioViewModes.TABLE_VIEW,
];

export const ImageViewModes = [
  StudioViewModes.GRID_VIEW,
  StudioViewModes.IMAGE_VIEW,
  StudioViewModes.RECORD_VIEW,
];

export const ContentViewModes = [
  StudioViewModes.IMAGE_VIEW,
  StudioViewModes.RECORD_VIEW,
  StudioViewModes.RAW,
];

export const GroupedContentViewModes = [
  StudioViewModes.GRID_VIEW,
  StudioViewModes.TABLE_VIEW,
];
