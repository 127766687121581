import Tooltip from '@coral/components/tooltips/TooltipV2';

interface NavTitleProps {
  title: string;
}

const NavTitle = ({ title }: NavTitleProps) => {
  if (!title) {
    return null;
  }

  return (
    <Tooltip title={title}>
      <span
        className="overflow-hidden text-ellipsis text-nowrap px-3 text-gray-500"
        data-cy={`nav-sidebar-app-${title}`}
      >
        {title}
      </span>
    </Tooltip>
  );
};

export default NavTitle;
