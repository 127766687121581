import tailwindTheme from 'tailwindcss/defaultTheme';
import type { ThemeConfig } from 'tailwindcss/types/config';

import type { SnorkelPalette } from '@snorkel-themes/themes/types';

import palette from './palette';
import semanticColors from './semanticVariables';

// import type { SnorkelPalette } from '../types';

// The additional colors here come from the legacy palette:
// https://www.figma.com/design/SqNJZTvByLQHLsb24s1lAk/Coral-2021?node-id=547-3670&m=dev

const coolGray = {
  100: '#F2F4F8',
  200: '#DDE1E6',
  300: '#C1C7CD',
  400: '#A2A9B0',
  500: '#878D96',
  600: '#697077',
  700: '#343A3F',
  800: '#21272A',
  900: '#121619',
  // This is currently duplicated from 900, but only because the current
  // value for 900 is incorrect in relation to palettes.
  1000: '#121619',
};

const lightGreen = {
  100: '#F5F8EF',
  200: '#E0EBCE',
  300: '#CCDEAE',
  400: '#B8D18D',
  500: '#A3C46C',
  600: '#99BD5C',
  700: '#7A974A',
  800: '#5C7137',
  900: '#3D4C25',
  1000: '#1F2612',
};

const indigo = {
  100: '#ECEBF4',
  200: '#C7C2DD',
  300: '#A199C6',
  400: '#7B70AF',
  500: '#564798',
  600: '#43328D',
  700: '#362871',
  800: '#281E55',
  900: '#1B1438',
  1000: '#0D0A1C',
};

const chartreuse = {
  100: '#F9F8EC',
  200: '#EDEAC7',
  300: '#E1DDA2',
  400: '#D5CF7D',
  500: '#C9C158',
  600: '#C3BA45',
  700: '#9C9537',
  800: '#757029',
  900: '#4E4A1C',
  1000: '#27250E',
};

const blueGrey = {
  100: '#CFD8DC',
  200: '#B0BEC5',
  300: '#90A4AE',
  400: '#78909C',
  500: '#607D8B',
  600: '#546E7A',
  700: '#455A64',
};

const brown = {
  100: '#D7CCC8',
  200: '#BCAAA4',
  300: '#A1887F',
  400: '#8D6E63',
  500: '#795548',
  600: '#6D4C41',
  700: '#5D4037',
};

const navy = {
  100: '#E8EAF5',
  200: '#D2D6F2',
  300: '#A1A8D5',
  400: '#7B86C2',
  500: '#5E6BB2',
  600: '#4555A5',
  700: '#323F8E',
  800: '#242B86',
  900: '#14116C',
  1000: '#110842',
};

const themePalette: SnorkelPalette = {
  ...palette,
  ...semanticColors,
};

/**
 * https://www.figma.com/file/SqNJZTvByLQHLsb24s1lAk/Coral-2021?node-id=3385%3A7956
 */
// This object contains the underlying palette, but also contains color
// variables that are semantic to their purpose. This object _also_ contains
// variables that _only_ exist in the legacy coral palette.
export const colors = {
  ...tailwindTheme.colors,
  ...themePalette,
  coolGray,
  gray: {
    ...palette.gray,
    text: '#747B83',
    border: coolGray['300'],
    background: coolGray['100'],
  },
  lightGreen,
  indigo,
  chartreuse,
  blueGrey,
  brown,
  navy,
  highlighter: {
    ...palette.highlighter,
    deprecated: '#B4D5FE',
  },
  transparent: 'transparent',
  current: 'currentColor',
  primary: palette.blue['700'],
  secondary: palette.purple['700'],
  tertiary: palette.teal['700'],
  alert: '#FF00DB',
} satisfies ThemeConfig['colors'];

export default null;
