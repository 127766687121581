import type { DropdownOptionProps } from './types';

function DropdownOption<T>({
  children,
  isDisabled = false,
}: DropdownOptionProps<T>) {
  return (
    <span
      className="select-none"
      aria-disabled={isDisabled}
      aria-label={children}
    >
      {children}
    </span>
  );
}

DropdownOption.displayName = 'DropdownOption';

export default DropdownOption;
