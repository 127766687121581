import tailwindConfig from 'tailwindcss/defaultConfig';
import type { ThemeConfig } from 'tailwindcss/types/config';

import type {
  SnorkelBorderRadius,
  SnorkelGap,
  SnorkelSpacing,
} from '@snorkel-themes/themes/types';

// https://tailwindcss.com/docs/border-radius
export const snorkelBorderRadius: SnorkelBorderRadius = {
  xs: '0.1rem', // There is no `xs` in Tailwind CSS
  sm: '0.125rem',
  md: '0.375rem',
  lg: '0.5rem',
};

export const borderRadius = {
  ...tailwindConfig.borderRadius,
  ...snorkelBorderRadius,
} satisfies ThemeConfig['borderRadius'];

const classicSpacing: SnorkelSpacing = {
  'cell-horizontal': '1rem',
  'cell-vertical': '0.75rem',
  'innerPanel-horizontal': '0.75rem',
  'innerPanel-vertical': '0.75rem',
  'inputField-horizontal': '0.5rem',
  'inputField-vertical': '0.5rem',
  'panel-horizontal': '1.5rem',
  'panel-vertical': '1.5rem',
  'content-gap': '0.75rem',
  'button-padding-horizontal-small': '0.5rem',
  'button-padding-horizontal-medium': '0.5rem',
  'button-padding-horizontal-large': '0.75rem',
  'button-padding-vertical-small': '0.125rem',
  'button-padding-vertical-medium': '0.25rem',
  'button-padding-vertical-large': '0.5rem',
};

export const coralPadding = {
  '1/2': '0.125rem',
  '5/2': '0.675rem',
  23: '4.5rem',
  28: '7rem',
  30: '7.5rem',
  50: '12.5rem',
  highlight: '1.5px',
  large: '3rem',
};

export const padding = {
  ...tailwindConfig.padding,
  ...classicSpacing,
  ...coralPadding,
} satisfies ThemeConfig['padding'];

export const margin = {
  ...tailwindConfig.margin,
  ...classicSpacing,
} satisfies ThemeConfig['margin'];

const classicGap: SnorkelGap = {
  inputField: '0.25rem',
  panel: '0.75rem',
};

export const gap = {
  ...tailwindConfig.gap,
  ...classicSpacing,
  ...classicGap,
} satisfies ThemeConfig['gap'];

export default null;
