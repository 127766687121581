import type { ParsedUrlQuery } from 'querystring';

import capitalize from 'lodash/capitalize';

import { ApplicationRoutes } from '@core/constants';
import type { NavigationMetaData } from '@global/state/reducers/navigation/types';

import removeUrlParams from './removeUrlParams';

import {
  ANNOTATE,
  APP_PRESET,
  APP_SETUP,
  APP_TEMPLATE,
  APPLICATIONS,
  BATCH,
  BATCHES,
  DATASETS,
  DEPLOYMENTS,
  DEVELOP,
  EVALUATE,
  EVALUATE_ALL_REPORTS,
  FILES,
  JOBS,
  LF_PACKAGES,
  OVERVIEW,
  PIPELINE,
  PROMPTS,
  SETTINGS,
} from '../constants';

type Route = {
  title: string;
  href: string;
  notificationIndicator?: boolean;
};

const cleanSlug = (slug: string) => capitalize(slug).replace(/([-_])/g, ' ');

const getTitleFromSlug = (slug: string): string => {
  switch (slug) {
    case 'overview':
      return OVERVIEW;
    case 'develop':
      return DEVELOP;
    case 'evaluate':
      return EVALUATE;
    case 'annotate':
      return ANNOTATE;
    case 'pipeline':
      return PIPELINE;
    case 'jobs':
      return JOBS;
    case 'preset':
      return APP_PRESET;
    case 'template':
      return APP_TEMPLATE;
    default:
      return slug;
  }
};

const appSetupRoute: Route = {
  title: APP_SETUP,
  href: 'applications/new',
};

const calcBreadcrumbNav = ({
  appName,
  asPath,
  meta,
  query,
}: {
  appName: string;
  asPath: string;
  meta: NavigationMetaData;
  query: ParsedUrlQuery;
}): Route[] => {
  const navItems: Route[] = [];

  const path = removeUrlParams(asPath).slice(1); // trim the leading '/' char

  if (path === '') return navItems;

  /*
possible applications paths:
- applications/new/app/[appId]
- applications/new/dataset/[datasetId]
- applications/template
- applications/preset
- applications/[appId]
- applications/[appId]/nodes/[nodeId]/annotate
- applications/[appId]/nodes/[nodeId]/annotate/[batchUid]
*/

  // renders the DAG page
  if (path.match(/applications\/[0-9]+$/)) {
    return [
      {
        title: appName,
        href: path,
      },
    ];
  }

  // new app/onboarding
  if (path.match(/^applications\/new$/)) {
    navItems.push(appSetupRoute);
  }

  // onboarding with app-id, or dataset preview step
  if (path.match(/^applications\/new\/(app|dataset)\/[0-9]+$/)) {
    if (appName) {
      navItems.push({
        title: appName,
        href: path,
      });
    }

    navItems.push({
      title: APP_SETUP,
      href: path,
    });
  }

  const newApplicationPathMatch = path.match(
    /^applications\/(preset|template)$/,
  );

  if (newApplicationPathMatch) {
    const slug = newApplicationPathMatch[1];

    return [
      {
        title: APPLICATIONS,
        href: 'applications',
      },
      {
        title: getTitleFromSlug(slug),
        href: path,
      },
    ];
  }

  const nodePathMatch = path.match(
    /^applications\/([0-9]+)\/nodes\/([0-9]+)\/(overview|develop|evaluate|pipeline|jobs)\/?([0-9]+|lf-packages|all-reports)?$/,
  );

  if (nodePathMatch) {
    const appId = nodePathMatch[1];
    const nodeId = nodePathMatch[2];
    const appPageSlug = nodePathMatch[3];
    const lastToken = nodePathMatch[4];

    const items = [
      {
        title: appName,
        href: `applications/${appId}/nodes/${nodeId}/${ApplicationRoutes.DEVELOP}`,
      },
      {
        title: getTitleFromSlug(appPageSlug),
        href: `applications/${appId}/nodes/${nodeId}/${appPageSlug}`,
      },
    ];

    if (lastToken === 'lf-packages') {
      items.push({
        title: LF_PACKAGES,
        href: path,
      });
    } else if (lastToken === 'all-reports') {
      items.push({
        title: EVALUATE_ALL_REPORTS,
        href: path,
      });
    }

    if (path.match(/\/evaluate\/([0-9]+)$/)) {
      items.push({
        title: `Report ID: ${lastToken}`,
        href: path,
      });
    }

    return items;
  }

  const pipelinePathMatch = path.match(/^applications\/([0-9]+)\/pipeline/);

  if (pipelinePathMatch) {
    const appId = pipelinePathMatch[1];

    return [
      {
        title: appName,
        href: `applications/${appId}`,
      },
      {
        title: PIPELINE,
        href: path,
      },
    ];
  }

  // applications/<app-id>/nodes/<node-id>/annotate/batches
  const annotateBatchesPathMatch = path.match(
    /^applications\/([0-9]+)\/nodes\/([0-9]+)\/annotate\/batches/,
  );

  if (annotateBatchesPathMatch) {
    const appId = annotateBatchesPathMatch[1];
    const nodeId = annotateBatchesPathMatch[2];

    return [
      {
        title: appName,
        href: `applications/${appId}/nodes/${nodeId}`,
      },
      {
        title: ANNOTATE,
        href: `applications/${appId}/nodes/${nodeId}/${ApplicationRoutes.ANNOTATE}`,
      },
      {
        title: BATCHES,
        href: `applications/${appId}/nodes/${nodeId}/${ApplicationRoutes.ANNOTATE}/batches`,
      },
    ];
  }

  // applications/<app-id>/nodes/<node-id>/annotate/<batch-id>
  const annotateBatchPathMatch = path.match(
    /^applications\/([0-9]+)\/nodes\/([0-9]+)\/annotate\/?([0-9]+)?$/,
  );

  if (annotateBatchPathMatch) {
    const appId = annotateBatchPathMatch[1];
    const nodeId = annotateBatchPathMatch[2];
    const batchId = annotateBatchPathMatch[3];
    navItems.push(
      {
        title: appName,
        href: `applications/${appId}/nodes/${nodeId}`,
      },
      {
        title: ANNOTATE,
        href: `applications/${appId}/nodes/${nodeId}/${ApplicationRoutes.ANNOTATE}`,
      },
    );

    if (batchId) {
      navItems.push({
        title: meta.batchName || '',
        href: path,
      });
    }
  }

  const batchPathMatch = path.match(
    /^applications\/([0-9]+)\/nodes\/([0-9]+)\/batch?$/,
  );

  if (batchPathMatch) {
    const appId = batchPathMatch[1];
    const nodeId = batchPathMatch[2];
    navItems.push(
      {
        title: appName,
        href: `applications/${appId}/nodes/${nodeId}`,
      },
      {
        title: ANNOTATE,
        href: `applications/${appId}/nodes/${nodeId}/${ApplicationRoutes.ANNOTATE}`,
      },
      {
        title: BATCH,
        href: path,
      },
    );
  }

  if (path.match(/deployments$/)) {
    return [];
  }

  if (path.match(/deployments\/[0-9]+\/sandbox$/)) {
    return [
      {
        href: 'deployments',
        title: DEPLOYMENTS,
      },
      {
        href: path,
        title: meta.deploymentName || '',
      },
    ];
  }

  /*
  datasets paths:
  - datasets
  - datasets/[datasetId]?selectedTab=sources
  - datasets/[datasetId]/batches/[batchId]/annotate
  */
  if (path.match(/^datasets\/[0-9]+$/)) {
    return [
      {
        title: DATASETS,
        href: 'datasets',
      },
      {
        title: meta.datasetName ?? '',
        href: path,
      },
    ];
  }

  const datasetPathMatch = path.match(
    /^datasets\/([0-9]+)\/batches\/([0-9]+)\/annotate/,
  );

  if (datasetPathMatch) {
    const datasetId = datasetPathMatch[1];

    return [
      {
        title: DATASETS,
        href: 'datasets',
      },
      {
        title: meta.datasetName || '',
        href: `datasets/${datasetId}?selectedTab=sources`,
      },
      {
        title: BATCHES,
        href: `datasets/${datasetId}?selectedTab=batches`,
      },
      {
        title: meta.batchName || '',
        href: `datasets/${datasetId}?selectedTab=batches`,
      },
    ];
  }

  const settingsPathMatch = path.match(/^(user|admin)\/settings\/?(.*)?$/);

  if (settingsPathMatch) {
    const settingsType = settingsPathMatch[1];
    const slug = settingsPathMatch[2];
    navItems.push(
      {
        title: cleanSlug(settingsType),
        href: `${settingsType}/settings`,
      },
      {
        title: SETTINGS,
        href: `${settingsType}/settings`,
      },
    );

    if (slug) {
      navItems.push({
        title: cleanSlug(slug),
        href: path,
      });
    }
  }

  if (path.match(/^files\/[^/]*$/)) {
    const folderName = query.folderName as string;

    return [
      {
        title: FILES,
        href: 'files',
      },
      {
        title: folderName,
        href: `files/${encodeURIComponent(folderName)}`,
      },
    ];
  }

  if (path.match(/^prompts\/[0-9]+$/)) {
    return [
      {
        title: PROMPTS,
        href: 'prompts',
      },
      {
        title: meta.promptWorkflow?.name || '',
        href: path,
      },
    ];
  }

  return navItems;
};

export default calcBreadcrumbNav;
