import { useDispatch, useSelector } from 'react-redux';

import * as actions from '@global/state/actions';
import type { NotificationId } from '@global/state/reducers/notifications/types';
import {
  NotificationDuration,
  NotificationVariant,
} from '@global/state/reducers/notifications/types';
import * as selectors from '@global/state/selectors';

import Notification from './Notification';
import ProgressBarNotification from './ProgressBarNotification';

const NotificationsContainer = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectors.notifications.selectAll);

  const handleClose = (uid: NotificationId) =>
    dispatch(actions.notifications.remove(uid));

  const calcAutoHideDuration = (variant: NotificationVariant) => {
    return variant === NotificationVariant.error
      ? NotificationDuration.TIMEOUT
      : NotificationDuration.DEFAULT;
  };

  return notifications.length > 0 ? (
    <div className="fixed bottom-32 right-32 z-notification flex w-112 flex-col">
      {notifications.map(({ uid, ...notification }) => {
        return notification.variant === NotificationVariant.progress ? (
          <ProgressBarNotification
            key={uid}
            notification={{ uid, ...notification }}
            onClose={handleClose}
          />
        ) : (
          <Notification
            key={uid}
            notification={{ uid, ...notification }}
            onClose={handleClose}
            autoHideDuration={calcAutoHideDuration(notification.variant)}
          />
        );
      })}
    </div>
  ) : null;
};

export default NotificationsContainer;
