import pipe from '@utils/pipe';

import type { NavigationState } from './types';

import type { RootState, Selector } from '../../types';

export const selectAll: Selector<NavigationState> = ({
  navigation,
}: RootState) => navigation;

export const selectNavigation: Selector<NavigationState['navigation']> = pipe(
  selectAll,
  ({ navigation }: NavigationState) => navigation,
);

export const selectCurrentApplicationNavigation: Selector<
  NavigationState['currentApplicationNavigation']
> = pipe(
  selectAll,
  ({ currentApplicationNavigation }: NavigationState) =>
    currentApplicationNavigation,
);

export const selectMeta: Selector<NavigationState['meta']> = pipe(
  selectAll,
  ({ meta }: NavigationState) => meta,
);

export const selectMetaObjectStorageUrl: Selector<
  NavigationState['meta']['objectStorageUrl']
> = pipe(
  selectMeta,
  ({ objectStorageUrl }: NavigationState['meta']) => objectStorageUrl,
);

export const selectMetaPromptWorkflow: Selector<
  NavigationState['meta']['promptWorkflow']
> = pipe(
  selectMeta,
  ({ promptWorkflow }: NavigationState['meta']) => promptWorkflow,
);

export default null;
