const iso8601ToSeconds = (durationStr: string): number => {
  // Validate the input is an ISO8601 duration string
  if (!/^P(T|\d|H|M|S)/.test(durationStr)) {
    throw new Error('Invalid ISO8601 duration string format');
  }

  // Regular expression to extract parts of the ISO8601 duration string
  const regex =
    /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)D)?T?(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;

  // Match the input string against the regex
  const match = durationStr.match(regex);

  if (!match) {
    throw new Error('Invalid ISO8601 duration string format');
  }

  // Extract each time component, defaulting to 0 if not present
  const [, years, months, days, hours, minutes, seconds] = match.map(val =>
    val ? parseInt(val, 10) : 0,
  );

  // Convert the components into seconds
  const totalSeconds =
    (years || 0) * 365 * 24 * 3600 + // Approximation for years
    (months || 0) * 30 * 24 * 3600 + // Approximation for months
    (days || 0) * 24 * 3600 +
    (hours || 0) * 3600 +
    (minutes || 0) * 60 +
    (seconds || 0);

  return totalSeconds;
};

export default iso8601ToSeconds;
